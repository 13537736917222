import './AccountStatus.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Template
import Private from '../../templates/private/Private'

// Router
import { Navigate } from 'react-router-dom'

// Zustand
import useUserStore from '../../../store/userStore'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Atoms
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

const AccountStatus = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const userStore = useUserStore()

	const Status = () => {
		// If user not exist redirect to login
		if (!userStore.user) return <Navigate to={commonSettings.apps.web.paths.login} replace={true} />

		// If user not active
		if (!userStore.user?.isActive) {
			return (
				<div className="AccountStatus_NotActive">
					<H2>{t('accountStatus:requireVerification.header')}</H2>

					<p>{t('accountStatus:requireVerification.description')}</p>

					<ul className="AccountStatus_Tips">
						<li>{t('accountStatus:requireVerification.tip1')}</li>
						<li>{t('accountStatus:requireVerification.tip2')}</li>
						<li>{t('accountStatus:requireVerification.tip3')}</li>
						<li>{t('accountStatus:requireVerification.tip4')}</li>
						<li>{t('accountStatus:requireVerification.tip5')}</li>
					</ul>

					<p>{t('accountStatus:requireVerification.thanks')}</p>
				</div>
			)
		}

		// If user blocked
		if (userStore.user?.isBlocked?.status) {
			const reason = userStore?.user?.isBlocked?.reason || ''

			return (
				<div className="AccountStatus_Blocked">
					<H2>{t('accountStatus:accountBlocked.header')}</H2>

					<p>{t('accountStatus:accountBlocked.reason', { reason })}</p>

					<p>{t('accountStatus:accountBlocked.description')}</p>

					<ul className="AccountStatus_Tips">
						<li>{t('accountStatus:accountBlocked.tip1')}</li>
						<li>{t('accountStatus:accountBlocked.tip2')}</li>
					</ul>

					<p>{t('accountStatus:accountBlocked.thanks')}</p>
				</div>
			)
		}

		// If user have no problem
		return <Navigate to={commonSettings.apps.web.paths.journal} replace={true} />
	}

	return (
		<ContentWrapper className="AccountStatus">
			<H1 isCentered={true}>{t('accountStatus:header')}</H1>
			<Status />
		</ContentWrapper>
	)
}

export default Private(AccountStatus)
