import axios from 'axios'

// Zustand
import useLanguageStore from '../store/languageStore'

// Shared
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

const apiPublicClient = axios.create({ baseURL: commonSettings.apps.api.url, headers: commonSettings.apps.api.headers })

// Request Interceptor
apiPublicClient.interceptors.request.use(
	async axiosConfig => {
		// Zustand
		const { selectedLanguage } = useLanguageStore.getState()

		// Add current language to header for every call
		axiosConfig.headers['Accept-Language'] = selectedLanguage?.isoCode

		// Bypass Ngrok browser warning
		if (process.env.NODE_ENV === 'development') {
			axiosConfig.headers['ngrok-skip-browser-warning'] = '69420'
		}

		return axiosConfig
	},
	error => Promise.reject(error)
)

// Expose only required methods
const apiPublic = {
	post: apiPublicClient.post,
	get: apiPublicClient.get,
	patch: apiPublicClient.patch
}

export default apiPublic
