import { useEffect, useState } from 'react'
import './DreamViewInterpretation.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import H4 from '../../../ruya-shared/shared/ui/atoms/h4/H4'
import StackButtons from '../../../ruya-shared/shared/ui/atoms/stackButtons/StackButtons'

// Store
import useJournalStore from '../../../store/journalStore'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream, IDreamQA, IInterpretation, IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

const DreamViewInterpretation = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId, interpreterId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Local State
	const [interpretation, setInterpretation] = useState<IInterpretation | null>(null)
	const [currentTab, setCurrentTab] = useState('analysis')
	const [error, setError] = useState<string | null>(null)

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [timelineId])

	// Load interpretation
	useEffect(() => {
		if (journalStore.currentEntry) {
			const tmpDream = (journalStore.currentEntry.entry as IDream) || null
			if (tmpDream && interpreterId) {
				const tmpInterpretation = tmpDream.interpretations?.find((interpretation: any) => interpretation.interpreterId === interpreterId)

				if (tmpInterpretation) {
					setInterpretation(tmpInterpretation)
				}
			}
		}
	}, [journalStore.currentEntry])

	const switchTabs = (tab: string) => {
		// Set the current tab
		setCurrentTab(tab)
	}

	const handleDelete = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteInterpretation'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteInterpretation(timelineId, interpreterId)

						if (response) {
							navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)
						} else {
							setError(journalStore.error)
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	// Loading
	if (!journalStore.loading)
		<ContentWrapper className="DreamViewInterpretation">
			<Loading />
		</ContentWrapper>

	return (
		<ContentWrapper className="DreamViewInterpretation">
			<div className="DreamViewInterpretation_Content">
				<div className="DreamViewInterpretation_TopBar">
					<div className="DreamViewInterpretation_TopBar_Left">
						<Button
							color="secondary"
							size="sm"
							icon="arrow_back"
							onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)}
							text={t('button:interpretedDreamTab')}
						/>
					</div>
					<div className="DreamViewInterpretation_TopBar_Right">
						<StackButtons>
							<Button
								color="secondary"
								size="sm"
								onClick={() => switchTabs('analysis')}
								text={t('button:analysis')}
								selected={currentTab === 'analysis'}
							/>

							<Button
								size="sm"
								iconFill="1"
								color="secondary"
								text={t('button:conversationHistoryTab')}
								onClick={() => switchTabs('conversation')}
								selected={currentTab === 'conversation'}
							/>

							<Button
								size="sm"
								iconFill="1"
								color="secondary"
								text={t('button:interpretedDreamTab')}
								onClick={() => switchTabs('dream')}
								selected={currentTab === 'dream'}
							/>
						</StackButtons>
					</div>
				</div>
				{currentTab === 'analysis' && (
					<div className="DreamViewInterpretation_Content_Analysis">
						<div className="DreamViewInterpretation_Content_Header">
							<MaterialSymbol name={interpretation?.interpreter.icon ?? ''} fill="1" />
							<H2>{interpretation?.interpreter.name}</H2>
						</div>

						<P className="DreamViewInterpretation_Content_Interpretation">{interpretation?.interpretation}</P>
					</div>
				)}
				{currentTab === 'conversation' && (
					<div className="DreamViewInterpretation_Content_DreamQA">
						<H2>{t('button:conversationHistoryTab')}</H2>
						{interpretation?.dreamQA.map((qa: IDreamQA, index: number) => {
							// Skip if the message is '-'
							if (qa.message === '-') return null

							return (
								<div
									key={index}
									className={`DreamViewInterpretation_Content_DreamQA_Item DreamViewInterpretation_Content_DreamQA_Item--${qa.sender}`}>
									{qa.sender !== 'user' && <MaterialSymbol name={interpretation?.interpreter.icon} fill="1" />}
									<div className="DreamViewInterpretation_Content_DreamQA_Item_Message">
										{qa.message === '-' ? t('journal:skippedAnswer') : qa.message}
									</div>
									{qa.sender === 'user' && <MaterialSymbol name="person" fill="1" />}
								</div>
							)
						})}
					</div>
				)}

				{currentTab === 'dream' && (
					<div className="DreamViewInterpretation_Content_Dream">
						<H2>{t('button:interpretedDreamTab')}</H2>
						<P>{interpretation?.dream}</P>
					</div>
				)}
			</div>

			<div className="DreamViewInterpretation_Delete">
				<div>
					<H4>{t('dialog:warningTitle')}</H4>
					<P size="sm">{t('journal:deleteInterpretationWarning')}</P>
				</div>

				<Button
					size="sm"
					color="danger"
					disabled={journalStore.loading}
					loading={journalStore.loading}
					text={t('button:delete')}
					icon="delete"
					onClick={handleDelete}
				/>

				{error && <p className="DreamViewInterpretation_Error">{error}</p>}
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamViewInterpretation)
