import { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import './RadioBox.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'
import P from '../p/P'

interface RadioBoxProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	label?: string
}

const RadioBox = (props: RadioBoxProps) => {
	const { className, type, label, ...rest } = props

	return (
		<div className="RadioBox">
			<label>
				<input className="RadioBox_Input" type="radio" {...rest} />
				<MaterialSymbol className="RadioBox_Radio_Unchecked" name="radio_button_unchecked" />
				<MaterialSymbol className="RadioBox_Radio_Checked" name="radio_button_checked" />
				{label && (
					<P size="sm" className="RadioBox_Label">
						{label}
					</P>
				)}
			</label>
		</div>
	)
}

export default RadioBox
