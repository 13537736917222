import type { ICommonSettings } from '../types/CommonTypes'

// Determine the ngrok address for development purposes
const apiAddress = process.env.EXPO_PUBLIC_NGROK_ADDRESS || process.env.REACT_APP_NGROK_ADDRESS || 'http://localhost:8080'
const apiCookieDomain = process.env.EXPO_PUBLIC_NGROK_COOKIE_DOMAIN || 'localhost'

export const commonSettings: ICommonSettings = {
	companyName: 'Ruya.co',
	defaultTimeZoneName: 'Europe/London',
	services: {
		isSignUpEnabled: true,
		isSignInEnabled: true,
		isForgotPasswordEnabled: true
	},
	google: {
		analytics: {
			trackingId: 'G-N6ZJLW7JLQ',
			tagManagerId: 'GTM-MDLGPSQV'
		}
	},
	microsoft: {
		clarity: {
			clarityId: 'm2mrbn6oxb'
		}
	},
	cloudFlare: {
		turnstile: {
			isEnabled: false,
			turnstileSiteKey: '0x4AAAAAAAEhzF3TlaUdaReb'
		}
	},
	indexNow: {
		key: '1208ffc7d5cc4011aa32ed8d6b2763fa',
		endpoint: 'https://api.indexnow.org/indexnow',
		bingEndpoint: 'https://www.bing.com/indexnow',
		yandexEndpoint: 'https://yandex.com/indexnow',
		naverEndpoint: 'https://searchadvisor.naver.com/indexnow',
		seznamEndpoint: 'https://search.seznam.cz/indexnow',
		yepEndpoint: 'https://indexnow.yep.com/indexnow'
	},
	apps: {
		next: {
			domain: 'https://ruya.co',
			url: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://ruya.co'
		},
		web: {
			domain: 'https://web.ruya.co',
			url: process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://web.ruya.co',
			paths: {
				home: '/', // Home is logged out path
				login: '/login',
				logout: '/logout',
				signUp: '/sign-up',
				language: '/language',
				journal: '/journal', // Logged in path
				accountStatus: '/account-status',
				forgotPassword: '/forgot-password',
				resetPassword: '/reset-password',
				changePassword: '/settings/change-password',
				activation: '/activation',
				loggedInLanguage: '/settings/language',
				subscription: '/settings/subscription',
				profile: '/settings/profile',
				challenge: '/challenge'
			}
		},
		api: {
			domain: 'https://api.ruya.co',
			url: process.env.NODE_ENV === 'development' ? apiAddress + '' : 'https://api.ruya.co',
			tokenName: 'ruya-token',
			cookieOptions: {
				expires: 7, // Cookie expires in 7 days
				path: '/', // Accessible for all paths on the domain
				domain: process.env.NODE_ENV === 'development' ? apiCookieDomain : '.ruya.co', // Accessible across all subdomains or localhost
				secure: true, // Only send the cookie over HTTPS
				httpOnly: false, // Allow client-side scripts to access the cookie since it's just a language setting
				sameSite: 'lax' // Secure enough for your use case and allows normal browsing use
			},
			headers: { 'Content-Type': 'application/json' }
		},
		admin: {
			domain: 'https://admin.ruya.co',
			url: process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://admin.ruya.co',
			paths: {
				home: '/', // Home is logged out path
				login: '/login',
				logout: '/logout',
				dashboard: '/dashboard' // Dashboard is logged in path
			}
		},
		cdn: {
			url: 'https://cdn.ruya.co/cdn',
			supportedImageFormats: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'],
			supportedVideoFormats: ['video/mp4', 'video/webm', 'video/ogg'],
			supportedFileFormats: ['application/pdf', 'text/plain', 'application/zip', 'application/x-zip-compressed']
		},
		whiteList: [
			'https://ruya.co',
			'https://web.ruya.co',
			'https://admin.ruya.co',
			'https://api.ruya.co',
			'https://cdn.ruya.co',
			'http://localhost:3000',
			'http://localhost:3001',
			'http://localhost:3002',
			'https://ruya.blob.core.windows.net'
		]
	}
}
