import type React from 'react'
import { useEffect, useState } from 'react'
import './TimelineList.scss'

// Store
import useJournalStore from '../../../store/journalStore'

// Molecules
import TimelineDream from '../timelineDream/TimelineDream'
import TimelineDiary from '../timelineDiary/TimelineDiary'
import TimelineEvent from '../timelineEvent/TimelineEvent'
import TimelineItemDate from '../timelineDate/TimelineDate'

// Types
import type { ITimeline } from '../../../ruya-shared/shared/types'

// Utils
import { groupByDate } from '../../../ruya-shared/shared/utils/timelineHelper'

interface DayGroupProps {
	date: string
	entries: ITimeline[]
}

// Map entry types to their corresponding components.
const entryComponentMap: Record<string, React.FC<{ data: ITimeline }>> = {
	dream: TimelineDream,
	diary: TimelineDiary,
	event: TimelineEvent
}

const DayGroup = ({ date, entries }: DayGroupProps) => (
	<div className="TimelineList_Day">
		<div className="TimelineList_Day_Date">
			<TimelineItemDate entryDate={date} />
			<div className="TimelineList_Day_Date_Line" />
		</div>

		<div className="TimelineList_Day_Records">
			{entries.map(entry => {
				const EntryComponent = entryComponentMap[entry.entryType]
				return EntryComponent ? <EntryComponent key={'entry' +String(entry._id)} data={entry} /> : null
			})}
		</div>
	</div>
)

const TimelineList = () => {
	// Store
	const journalStore = useJournalStore()

	const [timelineItems, setTimelineItems] = useState<Record<string, ITimeline[]>>({})

	// Grouping entries by date in useEffect
	useEffect(() => {
		setTimelineItems(groupByDate(journalStore.timelineData.list))
	}, [journalStore.timelineData.list])

	// Do not show the list if in welcome mode or there is an error
	if (journalStore.timelineData.welcome || journalStore.error) return null

	return (
		<div className="TimelineList">
			{Object.entries(timelineItems).map(([date, entries]) => (
				<DayGroup key={'dayGroup'+date} date={date} entries={entries} />
			))}
		</div>
	)
}

export default TimelineList
