import { useEffect } from 'react'
import './DiaryEdit.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import DateSelector, { CustomDateSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Utils
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'
import { DateTime } from 'luxon'

// Validation schema
import { updateDiarySchema } from '../../../validation/diarySchema'

const DiaryEdit = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// Load sleep session
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		}
	}, [timelineId])

	// React Hook Form
	const initialDiary = {
		date: new Date(),
		content: ''
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialDiary,
		mode: 'onChange' as const,
		resolver: yupResolver(updateDiarySchema)
	}
	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	// Load dream if timeline id
	useEffect(() => {
		if (journalStore.currentEntry !== null && timelineId) {
			const { entry, date } = journalStore.currentEntry

			if (entry) {
				reset({
					date: date ? new Date(date) : new Date(),
					content: entry.content || ''
				})
			}
		}
	}, [journalStore.currentEntry, timelineId])

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { date, content } = values

		// Add dream
		const isUpdated = await journalStore.updateDiary(timelineId, date, content)

		// Redirect to view dream
		if (isUpdated) {
			navigate(`${commonSettings.apps.web.paths.journal}/diary/${timelineId}`)
		}
	}

	// Cancel diary handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelEntry'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(`${commonSettings.apps.web.paths.journal}/diary/${timelineId}`)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(`${commonSettings.apps.web.paths.journal}/diary/${timelineId}`)
		}
	}

	// Delete diary handler
	const handleDelete = () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteDiary'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteEntry(timelineId)
						if (response) {
							navigate(commonSettings.apps.web.paths.journal)
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	return (
		<ContentWrapper className="DiaryEdit">
			<div className="DiaryAdd_Wrapper">
				<div className="DiaryEdit_Content_TopBar">
					<Button color="secondary" size="sm" icon="arrow_back" iconFill="1" onClick={handleCancel} text={t('button:journal')} />

					<div>
						<Button
							color="danger"
							size="sm"
							disabled={isSubmitting || journalStore.loading}
							text={t('button:delete')}
							onClick={handleDelete}
						/>
						<Button
							size="sm"
							loading={isSubmitting || journalStore.loading}
							onClick={() => handleSubmit(onSubmit)()}
							text={t('button:save')}
						/>
					</div>
				</div>

				<H2 isCentered={false}>{t('journal:editDiaryHeader')}</H2>

				<Form className="DiaryEdit_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="DiaryEdit_Content_DiaryInfo">
						<Controller
							control={control}
							name="date"
							render={({ field }) => {
								const { value, onChange, ref, ...rest } = field
								return (
									<DateSelector
										customInput={
											<CustomDateSelector
												icon="event"
												className="DiaryEdit_Date"
												text={formatDisplayDate(
													languageStore.selectedLanguage?.isoCode + '',
													value,
													DateTime.DATE_MED
												)}
											/>
										}
										dateFormat="Pp"
										innerRef={ref}
										autoComplete="off"
										maxDate={new Date()}
										invalid={Boolean(errors?.date?.message)}
										invalidMessage={errors?.date?.message}
										selected={value}
										onChange={date => onChange(date)}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										{...rest}
									/>
								)
							}}
						/>
					</div>

					<FormGroup>
						{journalStore.error && <p className="DiaryEdit_Form_Error">{journalStore.error}</p>}

						<TextArea
							resize="vertical"
							placeholder={t('form:diary.placeholder')}
							invalid={Boolean(errors.content?.message)}
							invalidMessage={errors.content?.message}
							{...register('content')}
						/>
					</FormGroup>
				</Form>
			</div>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(DiaryEdit)
