import { forwardRef, useEffect, useState } from 'react'
import './ToggleButton.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface ToggleButtonProps {
	icon: string
	checkedIcon: string
	iconFill?: '0' | '1'
	checkedIconFill?: '0' | '1'
	onChange: (value: boolean) => void
	value?: boolean
	defaultValue?: boolean
	className?: string
	loading?: boolean
}

const ToggleButton = forwardRef<HTMLLabelElement, ToggleButtonProps>((props, ref) => {
	const { className, icon, checkedIcon, iconFill = '0', checkedIconFill = '0', onChange, value, defaultValue = false, loading } = props

	const [checked, setChecked] = useState(defaultValue)

	useEffect(() => {
		if (value !== undefined && !loading) {
			setChecked(value)
		}
	}, [value])

	// Classes
	const classNames = 'ToggleButton' + (className ? ' ' + className : '') + (checked ? ' ToggleButton--checked' : '')

	const handleToggle = () => {
		const newChecked = !checked
		setChecked(newChecked)
		onChange(newChecked)
	}

	return (
		<label ref={ref} className={classNames} onClick={handleToggle}>
			<input type="checkbox" checked={checked} onChange={handleToggle} />
			{loading && <MaterialSymbol name="progress_activity" className="css-spin" />}
			{!loading &&
				(checked ? <MaterialSymbol name={checkedIcon} fill={checkedIconFill} /> : <MaterialSymbol name={icon} fill={iconFill} />)}
		</label>
	)
})

export default ToggleButton
