import './TimelineLoadMore.scss'

// Store
import useJournalStore from '../../../store/journalStore'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'

// Translation
import { useTranslation } from 'react-i18next'

const TimelineLoadMore = () => {
	// Store
	const journalStore = useJournalStore()

	// Translation Hook
	const { t } = useTranslation()

	const handleLoadMore = async () => {
		await journalStore.loadTimelineMore()
	}

	if (journalStore.loading || journalStore.error) return null

	if (journalStore.timelineData.page < journalStore.timelineData.totalPages) {
		return (
			<div className="TimelineLoadMore">
				<Button onClick={handleLoadMore} color="secondary" text={t('button:loadMore')} />
			</div>
		)
	}

	return null
}

export default TimelineLoadMore
