import './Label.scss'

interface ILabelProps {
	children: string
	className?: string
	size?: 'md' | 'lg' | 'sm'
	htmlFor?: string
}

const Label = (props: ILabelProps) => {
	const { children, className, size = 'md', htmlFor, ...rest } = props

	const classNames = 'Label' + ` Label--${size}` + `${className ? ' ' + className : ''}`

	return (
		<label className={classNames} htmlFor={htmlFor} {...rest}>
			{children}
		</label>
	)
}

export default Label
