// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Types
import type { TimelineDataState, TimelineState } from '../@types/journal'
import type { IDream, IInterpretation, ITimeline } from '../ruya-shared/shared/types'

// Utils
import { apiErrorHandler, errorHandler } from '../ruya-shared/shared/utils/errorHelper'

const initialState: TimelineDataState = {
	timelineData: {
		welcome: true,
		page: 1,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
		list: []
	},
	filters: {
		keyword: '',
		favorite: 'false',
		entryType: 'all',
		entryYear: 'all',
		entryMonth: 'all'
	},
	currentEntry: null,
	interpreting: false,
	loadingFavorite: null,
	loadingInterpretation: false,
	loading: false,
	error: null
}

const useJournalStore = create<TimelineState>((set, get) => ({
	...initialState,
	setLoading: loading => set({ loading }),
	setApiError: error => set({ error }),
	// Timeline
	loadTimeline: async (resetFilters = false) => {
		// Set loading
		set({ loading: true, error: null })

		// Reset filters if needed
		if (resetFilters) {
			set({ filters: initialState.filters })
		}

		// Always reset page to 1
		set({ timelineData: { ...get().timelineData, page: initialState.timelineData.page } })

		// Get pagination from sleepSessionsData
		const { page, pageSize } = get().timelineData

		// Build query params
		const params = new URLSearchParams({
			page: page.toString(),
			pageSize: pageSize.toString()
		})
		// Add filters to query params
		Object.entries(get().filters).forEach(([key, value]) => {
			// Only add if the value is not an empty string, undefined, or null.
			if (value !== '' && value !== undefined && value !== null && value !== 'false' && value !== 'all') {
				params.append(key, value.toString())
			}
		})

		try {
			const { data, status } = await apiProtected.get(`/timeline?${params.toString()}`)

			// Check axios error
			if (status !== 200) set({ error: 'Error fetching sleep timeline item', loading: false })

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ timelineData: data.data, loading: false })
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	},
	loadTimelineMore: async () => {
		// Set loading
		set({ loading: true, error: null })

		// Get pagination from sleepSessionsData
		const { page, pageSize, totalPages } = get().timelineData

		// Build query params
		const params = new URLSearchParams({
			page: (page + 1).toString(),
			pageSize: pageSize.toString()
		})
		// Add filters to query params
		Object.entries(get().filters).forEach(([key, value]) => {
			// Only add if the value is not an empty string, undefined, or null.
			if (value !== '' && value !== undefined && value !== null && value !== 'false' && value !== 'all') {
				params.append(key, value.toString())
			}
		})

		// Check if there are more pages
		if (page < totalPages) {
			try {
				const { data, status } = await apiProtected.get(`/timeline?${params.toString()}`)

				// Check axios error
				if (status !== 200) set({ error: 'Error fetching sleep sessions', loading: false })

				// Check API error
				if (data.status !== 'success') set({ error: data.message, loading: false })

				set({
					timelineData: {
						...data.data,
						list: [...get().timelineData.list, ...data.data.list]
					},
					loading: false
				})
			} catch (error) {
				set({ error: errorHandler(error), loading: false })
			}
		} else {
			set({ loading: false })
		}
	},
	loadTimelineEntry: async timeLineId => {
		set({ loading: true, error: null })

		try {
			const { data, status } = await apiProtected.get('/timeline/' + timeLineId)

			// Check axios error
			if (status !== 200) set({ error: 'Error fetching sleep session', loading: false })

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ currentEntry: data.data, loading: false })
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	},
	deleteEntry: async timelineId => {
		set({ loading: true, error: null })

		try {
			const response = await apiProtected.delete('/timeline/' + timelineId)

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}

			set({
				loading: false,
				error: null,
				timelineData: {
					...get().timelineData,
					list: get().timelineData.list.filter((item: ITimeline) => item._id !== timelineId)
				}
			})
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	toggleFavorite: async (timelineId, isFavorite) => {
		set({ loadingFavorite: timelineId, error: null })

		try {
			const response = await apiProtected.put('/timeline/favorite', { timelineId, isFavorite })

			// Check API error
			if (response?.data?.status !== 'success') {
				set({ error: response.data.message, loadingFavorite: null })
				return false
			}

			// Update state
			set({
				loadingFavorite: null,
				timelineData: {
					...get().timelineData,
					list: get().timelineData.list.map((item: ITimeline) => (item._id === timelineId ? { ...item, isFavorite } : item))
				},
				currentEntry: get().currentEntry?._id === timelineId ? { ...get().currentEntry, isFavorite } : get().currentEntry
			})
			return true
		} catch (error) {
			// Set error
			set({ loadingFavorite: null, error: apiErrorHandler(error) })
			return false
		}
	},

	// Dream
	addDream: async (dream, date, isLucidDream) => {
		set({ loading: true, error: null })

		try {
			// Create dream
			const response = await apiProtected.post('/timeline/dream', {
				dream,
				date,
				isLucidDream
			})

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return null
			}

			set({ loading: false, currentEntry: response.data.data })

			return response.data.data._id as string
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return null
		}
	},
	updateDream: async (timelineId, date, dream, isLucidDream) => {
		set({ loading: true, error: null })

		try {
			// Update dream
			const response = await apiProtected.put('/timeline/dream', {
				timelineId,
				date,
				dream,
				isLucidDream
			})

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}

			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	// Diary
	addDiary: async (content, date) => {
		set({ loading: true, error: null })

		try {
			// Create dream
			const response = await apiProtected.post('/timeline/diary', {
				content,
				date
			})
			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return null
			}
			set({ loading: false, currentEntry: response.data.data })
			return response.data.data._id as string
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return null
		}
	},
	updateDiary: async (timelineId, date, content) => {
		set({ loading: true, error: null })

		try {
			// Update diary
			const response = await apiProtected.put('/timeline/diary', {
				timelineId,
				date,
				content
			})
			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}
			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	// Event
	addEvent: async (date, title, content, icon) => {
		set({ loading: true, error: null })

		try {
			// Create dream
			const response = await apiProtected.post('/timeline/event', {
				date,
				title,
				content,
				icon
			})
			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return null
			}
			set({ loading: false, currentEntry: response.data.data })
			return response.data.data._id as string
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return null
		}
	},
	updateEvent: async (timelineId, date, title, content, icon) => {
		set({ loading: true, error: null })

		try {
			// Update diary
			const response = await apiProtected.put('/timeline/event', {
				timelineId,
				date,
				title,
				content,
				icon
			})
			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}
			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	},
	// Journal
	addJournal: async entry => {
		try {
			const response = await apiProtected.post('/journal', { entry })

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message })
				return null
			}

			return response.data.data._id as string
		} catch (error) {
			set({ error: apiErrorHandler(error) })
			return null
		}
	},
	updateJournal: async (timelineId, entry) => {
		try {
			const response = await apiProtected.put('/journal', { timelineId, entry })

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message })
				return false
			}

			return true
		} catch (error) {
			set({ error: apiErrorHandler(error) })
			return false
		}
	},
	// Interpretation
	interpret: async (timelineId, interpreterId, answer) => {
		// Avoid multiple requests
		if (get().loadingInterpretation) return false

		set({ loadingInterpretation: true, error: null })

		// Add users anser optimistically.
		set(state => {
			if (!state.currentEntry?.entry?.interpretations) return state
			return {
				currentEntry: {
					...state.currentEntry,
					entry: {
						...state.currentEntry.entry,
						interpretations: state.currentEntry.entry.interpretations.map((interpretation: { interpreterId: any; dreamQA: any }) =>
							interpretation.interpreterId === interpreterId
								? {
										...interpretation,
										dreamQA: [...interpretation.dreamQA, { sender: 'user', message: answer }]
									}
								: interpretation
						)
					}
				}
			}
		})

		try {
			const response = await apiProtected.post('/ai/dream/interpret/qa', { timelineId, interpreterId, answer })

			// Check API error
			if (response?.data?.status !== 'success') {
				set({ error: response.data.message, loadingInterpretation: false })
				return false
			}

			// Update current sleep session
			set({ currentEntry: response.data.data, loadingInterpretation: false, error: null })
			return true
		} catch (error) {
			// Set error
			set({ loadingInterpretation: false, error: apiErrorHandler(error) })
			return false
		}
	},
	deleteInterpretation: async (timelineId, interpretationId) => {
		set({ loading: true, error: null })

		try {
			const response = await apiProtected.delete('/journal/dream/interpretation/' + timelineId + '/' + interpretationId)

			// Check API error
			if (response?.data.status !== 'success') {
				set({ error: response.data.message, loading: false })
				return false
			}

			set({ loading: false })
			return true
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
			return false
		}
	}
}))

export default useJournalStore
