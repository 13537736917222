import { useTranslation } from 'react-i18next'
import './TimelineDate.scss'

// Sleep Session Date
interface TimelineDateProps {
	entryDate: string | Date | undefined
}

const TimelineDate = (props: TimelineDateProps) => {
	const { entryDate } = props

	const { i18n } = useTranslation()

	if (!entryDate) return null

	const tmpSessionDate = new Date(entryDate) // Sleep session date
	const currentDate = new Date() // Current date

	const shortMonthName = tmpSessionDate.toLocaleDateString(i18n.language, { month: 'short' })
	const isCurrentYear = tmpSessionDate.getFullYear() === currentDate.getFullYear()

	return (
		<div className="TimelineDate">
			<span className="TimelineDate_Day">{tmpSessionDate.getDate()}</span> 
			<span className="TimelineDate_Month">{shortMonthName}</span>
			{!isCurrentYear && <span className="TimelineDate_Year">{tmpSessionDate.getFullYear()}</span>}
		</div>
	)
}

export default TimelineDate
