import { useEffect, useState } from 'react'
import './DreamInterpretation.scss'

// Template
import Private from '../../templates/private/Private'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Organisms
import DreamQA from '../../organisms/dreamQA/DreamQA'

// Localization
import { useTranslation } from 'react-i18next'

// Types
import type { IDream, IInterpretation, IInterpreter } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const DreamInterpretation = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId, interpreterId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()

	// Local state
	const [dream, setDream] = useState<IDream>()
	const [interpreter, setInterpreter] = useState<IInterpreter>()
	const [interpretation, setInterpretation] = useState<IInterpretation>()
	const [interpretationRequested, setInterpretationRequested] = useState(false)

	// Check if all parameters passed correctly
	useEffect(() => {
		if (!timelineId || !interpreterId) {
			navigate('/journal')
		} else {
			// Load timeline entry
			journalStore.loadTimelineEntry(timelineId)
		}
	}, [])

	// When currentTimeline Entry loaded
	useEffect(() => {
		// Define async function
		const fetchData = async () => {
			// Check if currentSleepSession and interpreterList loaded.
			if (journalStore.currentEntry && interpreterStore.interpreterList.length > 0) {
				// Get dream
				const tmpDream = journalStore.currentEntry.entry as IDream
				setDream(tmpDream)

				// Get interpreter
				const tmpInterpreter = await interpreterStore.getInterpreter(interpreterId)

				setInterpreter(tmpInterpreter)

				// Find interpretation for current interpreter
				const tmpInterpretation = tmpDream?.interpretations?.find(
					(interpretation: IInterpretation) => interpretation.interpreter?.id?.toString() === interpreterId
				)

				// If found, set interpretation
				if (tmpInterpretation) {
					setInterpretation(tmpInterpretation)
				} else {
					if (!interpretationRequested) {
						setInterpretationRequested(true)
						journalStore.interpret(timelineId, interpreterId)
					}
				}
			}
		}

		// Call async function
		fetchData()
	}, [journalStore.currentEntry, interpreterStore.interpreterList])

	// Handle interpretation updates and redirection
	useEffect(() => {
		if (interpretation) {
			if (interpretation.isDreamQACompleted) {
				navigate(`/journal/dream/interpretation/${timelineId}/${interpretation.interpreterId?.toString()}`)
			} else {
				// Interpretation is not completed, continue with QA
				setInterpretation(interpretation)
			}
		}
	}, [interpretation])

	// Loading
	if (journalStore.loading) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<Loading />
			</ContentWrapper>
		)
	}

	// Not loaded yet
	if (!journalStore.currentEntry || !interpreter) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<Loading />
			</ContentWrapper>
		)
	}

	// Error
	if (journalStore.error) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<div className="DreamInterpretation_Wrapper DreamInterpretation_Wrapper--error">
					<HistoryBackLink />
					<InfoBox type="error" icon="error" header={t('errors:errorTitle')} text={journalStore.error} />
					<div />
				</div>
			</ContentWrapper>
		)
	}

	// Handle answer
	const handleAnswer = (answer: string) => {
		// Send answer to server
		journalStore.interpret(timelineId, interpreterId, answer)
	}

	return (
		<ContentWrapper className="DreamInterpretation">
			<div className="DreamInterpretation_Wrapper">
				<div className="DreamInterpretation_TopBar">
					<Button
						color="secondary"
						size="sm"
						icon="arrow_back"
						iconFill="1"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)}
						text={t('button:analyse')}
					/>
				</div>

				<H2>{interpreter?.name}</H2>

				<div className="DreamInterpretation_QA">
					<DreamQA interpreter={interpreter} interpretation={interpretation} dream={dream?.dream} handleAnswer={handleAnswer} />
				</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamInterpretation)
