import { useState } from 'react'
import './Interpreter.scss'

// Translation
import { useTranslation } from 'react-i18next'

// Types
import type { IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Badge from '../../../ruya-shared/shared/ui/atoms/badge/Badge'

interface InterpreterProps {
	interpreter: IInterpreter
	handleInterpret: (id: ObjectId) => void
	isInterpreted?: boolean
}

const Interpreter = (props: InterpreterProps) => {
	const { interpreter, handleInterpret, isInterpreted = false } = props

	// Translation Hooks
	const { t } = useTranslation()

	// Local state
	const [showDetails, setShowDetails] = useState(false)

	return (
		<div className={`Interpreter${showDetails ? ' Interpreter--showDetails' : ''}`} key={interpreter._id?.toString()}>
			<div className="Interpreter_Icon">
				<MaterialSymbol name={interpreter?.icon} fill="1" />
			</div>

			<div className="Interpreter_Explanation">
				<div className="Interpreter_Explanation_Title">
					<P size="lg">
						{interpreter?.name}
					</P>
					{isInterpreted && (
						<Badge size="sm" color="light">
							{t('journal:analysed')}
						</Badge>
					)}
				</div>

				<div className="Interpreter_Explanation_Description">
					<P size="sm">{interpreter?.description}</P>

					{!showDetails && (
						<div className="Interpreter_Explanation_More">
							<p onClick={() => setShowDetails(!showDetails)}>{t('button:seeMore')}</p>
						</div>
					)}
				</div>

				{showDetails && (
					<div className="Interpreter_Explanation_Details">
						<P size="sm">
							<span>{t('journal:theoryFocus')}</span> {interpreter?.focus}
						</P>

						<P size="sm">
							<span>{t('journal:theoryBenefit')}</span> {interpreter?.benefit}
						</P>

						<div className="Interpreter_Explanation_Less">
							<p onClick={() => setShowDetails(!showDetails)}>{t('button:seeLess')}</p>
						</div>
					</div>
				)}
			</div>

			<div className="Interpreter_Button">
				{isInterpreted ? (
					<Button color="secondary" size="sm" onClick={() => handleInterpret(interpreter._id as ObjectId)} icon="chevron_right" />
				) : (
					<Button color="secondary" size="sm" onClick={() => handleInterpret(interpreter._id as ObjectId)} icon="chevron_right" />
				)}
			</div>
		</div>
	)
}

export default Interpreter
