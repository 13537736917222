import { useEffect, useRef, useState } from 'react'
import './DropdownMenu.scss'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

interface DropdownMenuProps {
	children: React.ReactNode
	onToggle: (value: boolean) => void
	isOpen?: boolean
	triggerRef?: React.RefObject<HTMLElement | null>
}

interface DropdownMenuItemProps {
	onClick?: () => void
	onChange?: (value: boolean) => void
	color?: 'primary' | 'danger'
	icon?: string
	text?: string
	checkedText?: string
	type?: 'button' | 'toggle'
	className?: string
	checkedIcon?: string
	iconFill?: '0' | '1'
	checkedIconFill?: '0' | '1'
	value?: boolean
	defaultValue?: boolean
	loading?: boolean
}

const DropdownMenu = (props: DropdownMenuProps) => {
	const { children, isOpen, onToggle, triggerRef } = props

	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!isOpen) return

		function handleClickOutside(event: Event) {
			const target = event.target as Node
			// Check if the click is outside both the dropdown container and the trigger element.
			if (containerRef.current && !containerRef.current.contains(target) && !triggerRef?.current?.contains(target)) {
				onToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [isOpen, onToggle, triggerRef])

	// Optionally, render nothing if the menu is closed
	if (!isOpen) return null

	return (
		<div className="DropdownMenu" ref={containerRef}>
			{children}
		</div>
	)
}

export const DropdownMenuItem = (props: DropdownMenuItemProps) => {
	const {
		className,
		onClick,
		onChange,
		icon,
		checkedIcon,
		color = 'primary',
		text,
		checkedText,
		type = 'button',
		iconFill = '0',
		checkedIconFill = '0',
		value,
		defaultValue = false,
		loading
	} = props

	const [checked, setChecked] = useState(defaultValue)

	const classNames =
		'DropdownMenuItem' +
		(className ? ' ' + className : '') +
		(color ? ' DropdownMenuItem--' + color : '') +
		(icon ? ' DropdownMenuItem--hasIcon' : '') +
		(checked ? ' DropdownMenuItem--checked' : '')

	useEffect(() => {
		if (type === 'toggle' && value !== undefined && !loading) {
			setChecked(value)
		}
	}, [value])

	const handleToggle = () => {
		if (onChange) {
			const newChecked = !checked
			setChecked(newChecked)
			onChange(newChecked)
		}
	}

	if (type === 'toggle') {
		return (
			<label className={classNames} onClick={handleToggle}>
				<input type="checkbox" checked={checked} onChange={handleToggle} />
				{loading && <MaterialSymbol name="progress_activity" className="css-spin" />}
				{!loading &&
					checkedIcon &&
					icon &&
					(checked ? (
						<>
							<MaterialSymbol name={checkedIcon} fill={checkedIconFill} /> {checkedText}
						</>
					) : (
						<>
							<MaterialSymbol name={icon} fill={iconFill} /> {text}
						</>
					))}
			</label>
		)
	}

	return (
		<div className={classNames} onClick={onClick}>
			{icon && <MaterialSymbol name={icon} fill="1" />}
			<span>{text}</span>
		</div>
	)
}

export default DropdownMenu
