import './ButtonContinueWith.scss'

// Utils
import { capitalizeFirstLetter } from '../../../utils/commonHelper'

interface ButtonContinueWithProps {
	children?: React.ReactNode
	icon: 'apple' | 'google'
	onClick: () => void
}

const iconMap: { [key: string]: string } = {
	apple: 'https://cdn.ruya.co/cdn/assets/svg/apple.svg',
	google: 'https://cdn.ruya.co/cdn/assets/svg/google.svg'
}

const ButtonContinueWith = (props: ButtonContinueWithProps) => {
	const { children, icon, onClick } = props
	const iconUrl = iconMap[icon]

	const classNames = `ButtonContinueWith ButtonContinueWith--${icon}`

	return (
		<button type="button" className={classNames} onClick={onClick}>
			{iconUrl && <img src={iconUrl} alt={capitalizeFirstLetter(icon)} />}
			<span>{children}</span>
		</button>
	)
}

export default ButtonContinueWith
