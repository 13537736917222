// This Higher Order Component wrap pages with main tag.
import type { ComponentType, FC, JSX } from 'react'
import './Private.scss'

// Header
import Header from '../../organisms/header/Header'

// Separating out the type declarations
type ComponentProps = JSX.IntrinsicAttributes
type PrivateWrapperProps<P> = P & ComponentProps

// This type means: given a Component P, return a new Component which also takes P as its props
function Private<P extends ComponentProps>(PageComponent: ComponentType<P>): FC<P> {
	const PageWrapper: FC<PrivateWrapperProps<P>> = props => {
		return (
			<div className="Private">
				<Header />

				{/* We spread the props here to pass them down to the PageComponent */}
				<PageComponent {...props} />
			</div>
		)
	}

	// This is a good practice to make debugging easier
	PageWrapper.displayName = `Private(${PageComponent.displayName || PageComponent.name || 'Component'})`

	return PageWrapper
}

export default Private
