export const eventOptions = [
	{ name: 'Accident', icon: 'personal_injury' },
	{ name: 'Anniversary', icon: 'celebration' },
	{ name: 'Birth', icon: 'cake' },
	{ name: 'Death', icon: 'deceased' },
	{ name: 'Graduation', icon: 'school' },
	{ name: 'Location', icon: 'home_pin' },
	{ name: 'Relationship', icon: 'favorite' },
	{ name: 'Health', icon: 'monitor_heart' },
	{ name: 'Work', icon: 'badge' },
	{ name: 'Custom', icon: 'event' }
]

export default eventOptions
