import { useEffect } from 'react'
import './Timeline.scss'

// Store
import useJournalStore from '../../../store/journalStore'

// Atoms
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

// Molecules
import TimelineError from '../../molecules/timelineError/TimelineError'
import TimelineLoadMore from '../../molecules/timelineLoadMore/TimelineLoadMore'
import TimelineLoading from '../../molecules/timelineLoading/TimelineLoading'
import TimelineWelcome from '../../molecules/timelineWelcome/TimelineWelcome'
import TimelineMenu from '../../molecules/timelineMenu/TimelineMenu'
import TimelineList from '../../molecules/timelineList/TimelineList'
import TimelineNoRecord from '../../molecules/timelineNoRecord/TimelineNoRecord'

// Localization
import { useTranslation } from 'react-i18next'

const Timeline = () => {
	// Store
	const journalStore = useJournalStore()

	// Translation Hook
	const { t } = useTranslation()

	// Load timeline data
	useEffect(() => {
		journalStore.loadTimeline(true)
	}, [])

	return (
		<div className="Timeline">
			<TimelineWelcome />

			{!journalStore.timelineData.welcome && (
				<div className="Timeline_Container">
					<H2>{t('journal:header')}</H2>

					<div className="Timeline_Area">
						<div className="Timeline_Area_Menu">
							<TimelineMenu />
						</div>

						<div className="Timeline_Area_Content">
							<TimelineList />
							<TimelineLoading />
							<TimelineError />
							<TimelineLoadMore />
							<TimelineNoRecord />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Timeline
