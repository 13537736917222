import { useRef, useState } from 'react'
import './TimelineDiary.scss'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ToggleButton from '../../../ruya-shared/shared/ui/atoms/toggleButton/ToggleButton'
import Badge from '../../../ruya-shared/shared/ui/atoms/badge/Badge'

// Molecules
import DropdownMenu, { DropdownMenuItem } from '../dropdownMenu/DropdownMenu'

// Store
import useJournalStore from '../../../store/journalStore'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Types
import type { IDiary, ITimeline, ObjectId } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Dialog
import { confirmAlert } from 'react-confirm-alert'

// Types
interface TimelineDiaryProps {
	data: ITimeline
}

const TimelineDiary = (props: TimelineDiaryProps) => {
	const { data } = props
	const [isMenuOpen, setMenuOpen] = useState(false)

	// Ref
	const triggerButtonRef = useRef<HTMLLabelElement | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Handle Favorite Toggle
	const handleFavoriteToggle = (timelineId: ObjectId, isFavorite: boolean) => {
		if (!journalStore.loadingFavorite) {
			journalStore.toggleFavorite(timelineId, isFavorite)
		}
	}

	// Options toggle
	const handleOptionsToggle = (isChecked: boolean) => {
		setMenuOpen(!isChecked)
	}

	// Handle Navigate Edit diary
	const handleEdit = (timelineId: ObjectId) => {
		setMenuOpen(false)
		navigate(`${commonSettings.apps.web.paths.journal}/diary/edit/${timelineId}`)
	}

	// Handle Delete dream
	const handleDelete = async (timelineId: ObjectId) => {
		// Close menu
		setMenuOpen(false)

		// Get item with id
		const timelineItem = document.getElementById(timelineId.toString())

		// Optimistic delete animation
		if (timelineItem) {
			timelineItem.classList.add('TimelineDiary--deleted')

			// Request delete
			const isDeleted = await journalStore.deleteEntry(timelineId)

			if (isDeleted) {
				// Remove deleted item
				setTimeout(() => {
					timelineItem.remove()
				}, 500)
			} else {
				// Remove deleted class name
				timelineItem.classList.remove('TimelineDiary--deleted')
			}
		}
	}

	const handleDeleteConfirmation = (timelineId: ObjectId) => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteDiary'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => handleDelete(timelineId)
				},
				{
					label: t('button:no')
				}
			]
		})
	}
	return (
		<div className="TimelineDiary" id={data._id?.toString()}>
			<div className="TimelineDiary_Header">
				<Badge className="TimelineDiary_Header_Title" color="diary" icon="history_edu" iconPosition="left" iconFill="1" size="sm">
					{t('journal:diarySubHeader')}
				</Badge>

				<div className="TimelineDiary_Header_Actions">
					<ToggleButton
						loading={journalStore.loadingFavorite === data._id?.toString()}
						icon="star"
						iconFill="0"
						checkedIcon="star"
						checkedIconFill="1"
						onChange={isChecked => data._id && handleFavoriteToggle(data._id, isChecked)}
						value={data.isFavorite}
					/>

					<ToggleButton
						ref={triggerButtonRef}
						icon="more_horiz"
						iconFill="0"
						checkedIcon="more_horiz"
						checkedIconFill="1"
						value={isMenuOpen}
						onChange={isChecked => handleOptionsToggle(isChecked)}
					/>
				</div>

				<DropdownMenu isOpen={isMenuOpen} onToggle={setMenuOpen} triggerRef={triggerButtonRef}>
					<DropdownMenuItem onClick={() => data._id && handleEdit(data._id)} text={t('button:edit')} icon="edit" />
					<DropdownMenuItem onClick={() => data._id && handleDeleteConfirmation(data._id)} text={t('button:delete')} icon="delete" color="danger" />
				</DropdownMenu>
			</div>

			<div className="TimelineDiary_Details">
				<div className="TimelineDiary_Details_Content">{(data.entry as IDiary).content}</div>
				<div className="TimelineDiary_Details_Gradient" />
			</div>

			<div className="TimelineDiary_Actions">
				<Button
					size="sm"
					block={true}
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/diary/${data._id}`)}
					color="secondary"
					text={t('button:viewDiary')}
				/>
			</div>
		</div>
	)
}

export default TimelineDiary
