import { useEffect } from 'react'
import './DiaryView.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import Badge from '../../../ruya-shared/shared/ui/atoms/badge/Badge'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import ToggleButton from '../../../ruya-shared/shared/ui/atoms/toggleButton/ToggleButton'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDiary } from '../../../ruya-shared/shared/types'

// Utils
import { DateTime } from 'luxon'
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'

const DiaryView = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// Load dream if timelineId is provided
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [])

	// Handle favorite toggle
	const handleFavoriteToggle = (timelineId: string | undefined, isFavorite: boolean) => {
		if (!journalStore.loadingFavorite) {
			journalStore.toggleFavorite(timelineId, isFavorite)
		}
	}

	// Loading
	if (journalStore.loading || !journalStore.currentEntry) {
		return (
			<ContentWrapper className="DiaryView">
				<div className="DiaryView_Content">
					<Loading />
				</div>
			</ContentWrapper>
		)
	}

	return (
		<ContentWrapper className="DiaryView">
			<div className="DiaryView_Content">
				<div className="DiaryView_Content_TopBar">
					<Button
						color="secondary"
						size="sm"
						icon="arrow_back"
						iconFill="1"
						onClick={() => navigate(commonSettings.apps.web.paths.journal)}
						text={t('button:journal')}
					/>
					<div>
						<ToggleButton
							loading={journalStore.loadingFavorite === timelineId}
							icon="star"
							iconFill="0"
							checkedIcon="star"
							checkedIconFill="1"
							onChange={isChecked => handleFavoriteToggle(timelineId, isChecked)}
							value={journalStore.currentEntry.isFavorite}
						/>

						<Button
							color="secondary"
							size="sm"
							onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/diary/edit/${timelineId}`)}
							text={t('button:edit')}
						/>
					</div>
				</div>

				<Badge className="DiaryView_Content_Title" color="diary" icon="history_edu" iconPosition="left" iconFill="1" size="sm">
					{t('journal:diarySubHeader')}
				</Badge>

				<div className="DiaryView_Content_DiaryInfo">
					<span className="DiaryView_Content_DiaryInfo_Date">
						<MaterialSymbol name="calendar_month" fill="1" />
						{formatDisplayDate(
							languageStore.selectedLanguage?.isoCode + '',
							journalStore.currentEntry?.date,
							DateTime.DATE_MED
						)}
					</span>
				</div>

				<div className="DiaryView_Content_Diary">{(journalStore.currentEntry.entry as IDiary).content}</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(DiaryView)
