import * as Yup from 'yup'
import i18n from '../i18n'

const today = new Date()
const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999) // Set to the end of today

export const addDreamSchema = Yup.object({
	date: Yup.date().required(i18n.t('form:date.required')).max(endOfDay, i18n.t('form:date.max')),
	dream: Yup.string().required(i18n.t('form:dream.required')),
	isLucidDream: Yup.boolean().required(i18n.t('form:isLucidDream.required'))
}).required()

export const updateDreamSchema = Yup.object({
	date: Yup.date().required(i18n.t('form:date.required')).max(endOfDay, i18n.t('form:date.max')),
	dream: Yup.string().required(i18n.t('form:dream.required')),
	isLucidDream: Yup.boolean().required(i18n.t('form:isLucidDream.required')),
}).required()
