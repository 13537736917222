import './TimelineError.scss'

// Store
import useJournalStore from '../../../store/journalStore'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

const TimelineError = () => {
	const journalStore = useJournalStore()

	if (!journalStore.loading && journalStore.error) {
		return (
			<div className="TimelineError">
				<MaterialSymbol name="error" /> <span>{journalStore.error}</span>
			</div>
		)
	}

	return null
}

export default TimelineError
