import { InputHTMLAttributes } from 'react'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'
import './SearchBox.scss'

// Localization
import { useTranslation } from 'react-i18next'

const SearchBox = (props: InputHTMLAttributes<HTMLInputElement>) => {
	// Translation Hook
	const { t } = useTranslation()

	return (
		<div className="SearchBox">
			<input className="SearchBox_Input" placeholder={t('form:journalSearch.placeholder')} {...props} />
			<MaterialSymbol name="search" />
		</div>
	)
}

export default SearchBox
