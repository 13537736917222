import { useRef, useState } from 'react'
import './TimelineDream.scss'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Label from '../../../ruya-shared/shared/ui/atoms/label/Label'
import ToggleButton from '../../../ruya-shared/shared/ui/atoms/toggleButton/ToggleButton'
import Badge from '../../../ruya-shared/shared/ui/atoms/badge/Badge'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Molecules
import DropdownMenu, { DropdownMenuItem } from '../dropdownMenu/DropdownMenu'

// Store
import useJournalStore from '../../../store/journalStore'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Types
import type { IDream, IInterpretation, ITimeline, ObjectId } from '../../../ruya-shared/shared/types'

// Settings
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { confirmAlert } from 'react-confirm-alert'

// Types
interface TimelineDreamProps {
	data: ITimeline
}

const TimelineDream = (props: TimelineDreamProps) => {
	const { data } = props
	const [isMenuOpen, setMenuOpen] = useState(false)

	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	// Ref
	const triggerButtonRef = useRef<HTMLLabelElement | null>(null)

	// Store
	const journalStore = useJournalStore()

	const goToInterpretation = (interpretation: IInterpretation) => {
		if (interpretation.isDreamQACompleted) {
			navigate(`/journal/dream/interpretation/${data._id}/${interpretation.interpreterId}`)
		} else {
			navigate(`/journal/dream-questionnaire/${data._id}/${interpretation.interpreterId}`)
		}
	}

	// Handle favorite toggle
	const handleFavoriteToggle = (timelineId: ObjectId, isFavorite: boolean) => {
		if (!journalStore.loadingFavorite) {
			journalStore.toggleFavorite(timelineId, isFavorite)
		}
	}

	// Options toggle
	const handleOptionsToggle = (isChecked: boolean) => {
		setMenuOpen(!isChecked)
	}

	// Handle Navigate Edit dream
	const handleEdit = (timelineId: ObjectId) => {
		setMenuOpen(false)
		navigate(`${commonSettings.apps.web.paths.journal}/dream/edit/${timelineId}`)
	}

	// Handle Delete dream
	const handleDelete = async (timelineId: ObjectId) => {
		// Close menu
		setMenuOpen(false)

		// Get item with id
		const timelineItem = document.getElementById(timelineId.toString())

		// Optimistic delete animation
		if (timelineItem) {
			timelineItem.classList.add('TimelineDream--deleted')

			// Request delete
			const isDeleted = await journalStore.deleteEntry(timelineId)

			if (isDeleted) {
				// Remove deleted item
				setTimeout(() => {
					timelineItem.remove()
				}, 500)
			} else {
				// Remove deleted class name
				timelineItem.classList.remove('TimelineDream--deleted')
			}
		}
	}

	const handleDeleteConfirmation = (timelineId: ObjectId) => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteDream'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => handleDelete(timelineId)
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	// Handle Navigate Analyse dream
	const handleAnalyse = (timelineId: ObjectId) => {
		setMenuOpen(false)
		navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${timelineId}`)
	}

	// Get interpretations
	const interpretations = (data.entry as IDream)?.interpretations ?? []

	return (
		<div className="TimelineDream" id={data._id?.toString()}>
			<div className="TimelineDream_Header">
				<Badge className="TimelineDream_Header_Title" color="dream" icon="partly_cloudy_night" iconFill="1" size="sm">
					{t('journal:dreamSubHeader')}
				</Badge>

				<div className="TimelineDream_Header_Actions">
					<ToggleButton
						loading={journalStore.loadingFavorite === data._id?.toString()}
						icon="star"
						iconFill="0"
						checkedIcon="star"
						checkedIconFill="1"
						onChange={isChecked => data._id && handleFavoriteToggle(data._id, isChecked)}
						value={data.isFavorite}
					/>

					<ToggleButton
						ref={triggerButtonRef}
						icon="more_horiz"
						iconFill="0"
						checkedIcon="more_horiz"
						checkedIconFill="1"
						value={isMenuOpen}
						onChange={isChecked => handleOptionsToggle(isChecked)}
					/>
				</div>

				<DropdownMenu isOpen={isMenuOpen} onToggle={setMenuOpen} triggerRef={triggerButtonRef}>
					<DropdownMenuItem onClick={() => data._id && handleEdit(data._id)} text={t('button:edit')} icon="edit" />

					<DropdownMenuItem onClick={() => data._id && handleAnalyse(data._id)} text={t('button:analyse')} icon="play_circle" iconFill="1" />

					<DropdownMenuItem onClick={() => data._id && handleDeleteConfirmation(data._id)} text={t('button:delete')} icon="delete" color="danger" />
				</DropdownMenu>
			</div>

			<div className="TimelineDream_Details">
				<div className="TimelineDream_Details_Content">{(data.entry as IDream).dream}</div>
				<div className="TimelineDream_Details_Gradient" />
			</div>

			{interpretations.length > 0 && (
				<div className="TimelineDream_Section">
					<Label className="TimelineDream_SubTitle">{t('journal:analyseSubHeader')}</Label>

					<div className="TimelineDream_Section_List">
						{interpretations.slice(0, 3).map((interpretation: IInterpretation) => (
							<div
								key={data._id + interpretation.interpreterId?.toString()}
								className="TimelineDream_Interpretation"
								onClick={() => goToInterpretation(interpretation)}>
								<MaterialSymbol name={interpretation.interpreter?.icon} fill="1" />
								<span className="TimelineDream_Interpretation_Name">{interpretation.interpreter?.name}</span>
							</div>
						))}

						<div>
							{interpretations.length > 3 && (
								<div className="TimelineDream_More">
									<p className="TimelineDream_More_Box">...</p>
									<p
										className="TimelineDream_More_Text"
										onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/${data._id}?#analysis`)}>
										{t('button:moreAnalysis', { count: Math.max(0, interpretations.length - 3) })}
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			<div className="TimelineDream_Actions">
				<Button
					size="sm"
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/${data._id}`)}
					color="secondary"
					text={t('button:viewDream')}
				/>

				<Button
					size="sm"
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${data._id}`)}
					text={t('button:analyse')}
				/>
			</div>
		</div>
	)
}

export default TimelineDream
