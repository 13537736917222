import type { ITimeline } from '../types'

// Function to group ITimeline records by date
export function groupByDate(records: ITimeline[]): Record<string, ITimeline[]> {
	return records.reduce(
		(acc, record) => {
			// Convert record.date to a Date object if it's not already
			const date = record.date instanceof Date ? record.date : new Date(record.date)

			// Ensure valid date conversion
			if (Number.isNaN(date.getTime())) {
				console.error('Invalid date found in record:', record)
				return acc // Skip invalid dates
			}

			const dateKey = date.toISOString().split('T')[0] // Extract YYYY-MM-DD

			if (!acc[dateKey]) acc[dateKey] = []

			acc[dateKey].push(record)

			return acc
		},
		{} as Record<string, ITimeline[]>
	)
}
