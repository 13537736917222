import * as Yup from 'yup'
import i18n from '../i18n'

import { getEndOfDayUTC } from '../ruya-shared/shared/utils/dateHelper'

export const addEventSchema = Yup.object({
	date: Yup.date().required(i18n.t('form:date.required')).max(getEndOfDayUTC(), i18n.t('form:date.max')),
	title: Yup.string().required(i18n.t('form:eventTitle.required')),
	content: Yup.string().required(i18n.t('form:content.required')),
	icon: Yup.string().required('form:eventIcon.required')
}).required()

export const updateEventSchema = Yup.object({
	date: Yup.date().required(i18n.t('form:date.required')).max(getEndOfDayUTC(), i18n.t('form:date.max')),
	title: Yup.string().required(i18n.t('form:eventTitle.required')),
	content: Yup.string().required(i18n.t('form:content.required')),
	icon: Yup.string().required('form:eventIcon.required')
}).required()
