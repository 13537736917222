import './TimelineWelcome.scss'

// Store
import useJournalStore from '../../../store/journalStore'

// Atoms
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'

// Translation
import { useTranslation } from 'react-i18next'

// Settings
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Router
import { useNavigate } from 'react-router-dom'

const TimelineWelcome = () => {
	// Store
	const journalStore = useJournalStore()

	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	if (!journalStore.loading && !journalStore.error && journalStore.timelineData.welcome) {
		return (
			<div className="TimelineWelcome">
				<H2 className="TimelineWelcome_Header" isCentered={true}>
					{t('common:welcome')}
				</H2>
				<P className="TimelineWelcome_Text">{t('journal:timelineWelcome')}</P>
				<div className="TimelineWelcome_Add">
					<Button
						color="secondary"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/diary/add`)}
						icon="history_edu"
						text={t('button:newDiary')}
						iconFill="1"
					/>
					<Button
						color="secondary"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/add`)}
						icon="partly_cloudy_night"
						text={t('button:newDream')}
						iconFill="1"
					/>
					<Button
						color="secondary"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/event/add`)}
						icon="event"
						text={t('button:addLifeEvent')}
						iconFill="1"
					/>
				</div>
			</div>
		)
	}

	return null
}

export default TimelineWelcome
