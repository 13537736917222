import './Journal.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'

// Molecules
import Timeline from '../../organisms/timeline/Timeline'

// Template
import Private from '../../templates/private/Private'

const Journal = () => {
	// Translation Hook
	const { t } = useTranslation()

	return (
		<ContentWrapper className="Journal">
						
			<Timeline />

		</ContentWrapper>
	)
}

export default Private(Journal)
