import { useEffect, useRef, useState } from 'react'
import './DreamView.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import H3 from '../../../ruya-shared/shared/ui/atoms/h3/H3'
import Badge from '../../../ruya-shared/shared/ui/atoms/badge/Badge'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import ToggleButton from '../../../ruya-shared/shared/ui/atoms/toggleButton/ToggleButton'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream, IInterpretation, ObjectId } from '../../../ruya-shared/shared/types'

// Utils
import { DateTime } from 'luxon'
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'
import DropdownMenu, { DropdownMenuItem } from '../../molecules/dropdownMenu/DropdownMenu'
import { confirmAlert } from 'react-confirm-alert'

const DreamView = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId } = useParams()
	const navigate = useNavigate()
	const location = useLocation()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// Ref
	const triggerButtonRef = useRef<HTMLLabelElement | null>(null)

	// Local state
	const [interpretations, setInterpretations] = useState<IInterpretation[] | null>([])
	const [isMenuOpen, setMenuOpen] = useState(false)

	// Load dream if timelineId is provided
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [timelineId])

	// Load dream interpretations
	useEffect(() => {
		if (journalStore.currentEntry) {
			setInterpretations((journalStore.currentEntry.entry as IDream)?.interpretations || [])
		}
	}, [journalStore.currentEntry])

	// Run when url has changed
	useEffect(() => {
		setTimeout(() => {
			const hash = location.hash
			if (hash) {
				const element = document.getElementById(hash.substring(1))
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'start' })
				}
			}
		}, 1000)
	}, [location])

	// Get interpretation status to redirect to correct page
	const goToInterpretation = (interpreterId: ObjectId) => {
		const tmpInterpretation = interpretations?.find((interpretation: any) => interpretation.interpreterId === interpreterId)

		if (tmpInterpretation?.isDreamQACompleted) {
			navigate(`/journal/dream/interpretation/${timelineId}/${interpreterId}`)
		} else {
			navigate(`/journal/dream-questionnaire/${timelineId}/${interpreterId}`)
		}
	}

	// Handle favorite toggle
	const handleFavoriteToggle = (timelineId: string | undefined, isFavorite: boolean) => {
		if (!journalStore.loadingFavorite) {
			journalStore.toggleFavorite(timelineId, isFavorite)
		}
	}

	//Scroll to Interpretations list
	const handleViewAnalysisClick = () => {
		const interpretationsList = document.getElementById('analysis')
		if (interpretationsList) {
			// Close menu
			setMenuOpen(false)

			interpretationsList.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
		}
	}

	// Options toggle
	const handleOptionsToggle = (isChecked: boolean) => {
		setMenuOpen(!isChecked)
	}

	// Handle Delete dream
	const handleDelete = async (timelineId: ObjectId) => {
		// Close menu
		setMenuOpen(false)

		// Get item with id
		const timelineItem = document.getElementById(timelineId.toString())

		// Optimistic delete animation
		if (timelineItem) {
			timelineItem.classList.add('TimelineDream--deleted')

			// Request delete
			const isDeleted = await journalStore.deleteEntry(timelineId)

			if (isDeleted) {
				// Remove deleted item
				setTimeout(() => {
					timelineItem.remove()
				}, 500)
			} else {
				// Remove deleted class name
				timelineItem.classList.remove('TimelineDream--deleted')
			}
		}
	}

	const handleDeleteConfirmation = (timelineId: ObjectId) => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteDream'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => handleDelete(timelineId)
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	// Loading
	if (journalStore.loading || !journalStore.currentEntry) {
		return (
			<ContentWrapper className="DreamView">
				<div className="DreamView_Content">
					<Loading />
				</div>
			</ContentWrapper>
		)
	}

	// Error
	if (journalStore.error) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<HistoryBackLink />
				<InfoBox type="error" icon="error" header={t('errors:errorTitle')} text={journalStore.error} />
			</ContentWrapper>
		)
	}

	return (
		<ContentWrapper className="DreamView">
			<div className="DreamView_Content">
				<div className="DreamView_TopBar">
					<div className="DreamView_TopBar_Left">
						<Button
							color="secondary"
							size="sm"
							icon="arrow_back"
							iconFill="1"
							onClick={() => navigate(commonSettings.apps.web.paths.journal)}
							text={t('button:journal')}
						/>
					</div>

					<div className="DreamView_TopBar_Right">
						<div className="DreamView_Actions">
							<ToggleButton
								ref={triggerButtonRef}
								icon="more_horiz"
								iconFill="0"
								checkedIcon="more_horiz"
								checkedIconFill="1"
								value={isMenuOpen}
								onChange={isChecked => handleOptionsToggle(isChecked)}
							/>

							<DropdownMenu isOpen={isMenuOpen} onToggle={setMenuOpen} triggerRef={triggerButtonRef}>
								{((journalStore.currentEntry.entry as IDream)?.interpretations?.length ?? 0) > 0 && (
									<DropdownMenuItem
										onClick={handleViewAnalysisClick}
										text={t('button:viewAnalysis', {
											count: (journalStore.currentEntry.entry as IDream)?.interpretations?.length || 0
										})}
										icon="stat_minus_2"
									/>
								)}

								<DropdownMenuItem
									onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/edit/${timelineId}`)}
									text={t('button:edit')}
									icon="edit"
								/>

								<DropdownMenuItem
									type="toggle"
									loading={journalStore.loadingFavorite === timelineId}
									icon="star"
									text={t('button:addToFavorites')}
									checkedText={t('button:removeFromFavorites')}
									iconFill="0"
									checkedIcon="star"
									checkedIconFill="1"
									onChange={isChecked => handleFavoriteToggle(timelineId, isChecked)}
									value={journalStore.currentEntry.isFavorite}
								/>

								<DropdownMenuItem
									onClick={() => journalStore.currentEntry._id && handleDeleteConfirmation(journalStore.currentEntry._id)}
									text={t('button:delete')}
									icon="delete"
									color="danger"
								/>
							</DropdownMenu>
						</div>

						<Button
							size="sm"
							onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${timelineId}`)}
							text={t('button:analyse')}
						/>
					</div>
				</div>

				<Badge className="DreamView_Content_Title" color="dream" icon="partly_cloudy_night" iconPosition="left" iconFill="1" size="sm">
					{t('journal:dreamSubHeader')}
				</Badge>

				<div className="DreamView_Content_DreamInfo">
					<span className="DreamView_Content_DreamInfo_Date">
						<MaterialSymbol name="calendar_month" fill="1" />
						{formatDisplayDate(languageStore.selectedLanguage?.isoCode + '', journalStore.currentEntry?.date, DateTime.DATE_MED)}
					</span>
					{(journalStore.currentEntry.entry as IDream).isLucidDream && (
						<>
							<span className="DreamView_Content_DreamInfo_Separator" />
							<span className="DreamView_Content_DreamInfo_Lucid">
								<MaterialSymbol name="visibility" fill="1" /> {t('journal:lucidDream')}
							</span>
						</>
					)}
				</div>

				<div className="DreamView_Content_Dream">{(journalStore.currentEntry.entry as IDream).dream}</div>

				<div className="DreamView_Interpretations">
					{((journalStore.currentEntry.entry as IDream)?.interpretations?.length ?? 0) > 0 && (
						<>
							<div className="DreamView_Interpretations_Analyse">
								<H3>
									{t('journal:analyseSubHeader')} ({(journalStore.currentEntry.entry as IDream)?.interpretations?.length || 0})
								</H3>

								<Button
									text={t('button:analyse')}
									size="sm"
									onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${timelineId}`)}
								/>
							</div>
							<div id="analysis" className="DreamView_InterpretationsList">
								{(journalStore.currentEntry.entry as IDream)?.interpretations?.map((interpretation: IInterpretation) => {
									return (
										<div
											key={interpretation.interpreterId?.toString()}
											className="DreamView_InterpretationsList_Item"
											onClick={() => goToInterpretation(interpretation?.interpreterId?.toString() || '')}>
											<MaterialSymbol
												name={interpretation.interpreter.icon}
												fill="1"
												className="DreamView_InterpretationsList_Item_Icon"
											/>
											<span className="DreamView_InterpretationsList_Item_Name">{interpretation.interpreter.name}</span>
											<MaterialSymbol name="chevron_right" className="DreamView_InterpretationsList_Item_Arrow" />
										</div>
									)
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamView)
