import { useEffect, useRef, useState } from 'react'
import './NavigationSettings.scss'

// Atoms
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Localization
import { useTranslation } from 'react-i18next'

// Store
import useUserStore from '../../../store/userStore'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const NavigationSettings = () => {
	// Zustand
	const userStore = useUserStore()

	// Translation Hook
	const { t } = useTranslation()

	// State
	const [showMenu, setShowMenu] = useState(false)

	// Create a ref for the outer container
	const containerRef = useRef<HTMLDivElement>(null)

	// Add an effect to handle clicks outside the container
	useEffect(() => {
		function handleClickOutside(event: Event) {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setShowMenu(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [])

	// If user is not logged in, return null
	if (!userStore.user) return null

	return (
		<div ref={containerRef} className={`NavigationSettings${showMenu ? ' NavigationSettings--open' : ''}`}>
			<div className="NavigationSettings_User" onClick={() => setShowMenu(!showMenu)}>
				<span>{userStore.user?.displayName}</span>
				<MaterialSymbol name="arrow_drop_down" weight="700" opticalSize="48" className="NavigationSettings_User_Arrow" />
			</div>
			<nav className="NavigationSettings_Menu">
				<div className="NavigationSettings_Menu_Item">
					<ReactLink icon="user_attributes" href={commonSettings.apps.web.paths.profile}>
						{t('links:profileDetails')}
					</ReactLink>
				</div>
				<div className="NavigationSettings_Menu_Item">
					<ReactLink icon="password" href={commonSettings.apps.web.paths.changePassword}>
						{t('links:changePassword')}
					</ReactLink>
				</div>
				{/* <div className="NavigationSettings_Menu_Item">
					<ReactLink icon="card_membership" href={commonSettings.apps.web.paths.subscription}>
						{t('links:manageSubscription')}
					</ReactLink>
				</div> */}
				<div className="NavigationSettings_Menu_Item">
					<ReactLink icon="language" href={commonSettings.apps.web.paths.loggedInLanguage}>
						{t('links:changeLanguage')}
					</ReactLink>
				</div>
				<div className="NavigationSettings_Menu_Item">
					<ReactLink icon="logout" href={commonSettings.apps.web.paths.logout}>
						{t('links:logout')}
					</ReactLink>
				</div>
			</nav>
		</div>
	)
}

export default NavigationSettings
