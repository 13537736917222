import { useEffect } from 'react'
import './DreamEdit.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import DateSelector, { CustomDateSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Utils
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'
import { DateTime } from 'luxon'

// Validation schema
import { updateDreamSchema } from '../../../validation/dreamSchema'

const DreamEdit = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// Load sleep session
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		}
	}, [timelineId])

	// React Hook Form
	const initialDream = {
		date: new Date(),
		dream: '',
		isLucidDream: false
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialDream,
		mode: 'onChange' as const,
		resolver: yupResolver(updateDreamSchema)
	}
	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (journalStore.currentEntry !== null && timelineId) {
			const { entry, date } = journalStore.currentEntry

			if (entry) {
				reset({
					dream: entry.dream || '',
					isLucidDream: entry.isLucidDream,
					date
				})
			}
		}
	}, [journalStore.currentEntry, timelineId])

	// Switch options
	const yesNoSwitchOptions = { on: { value: true, label: t('options:yes') }, off: { value: false, label: t('options:no') } }

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { date, dream, isLucidDream } = values

		// Add dream
		const isUpdated = await journalStore.updateDream(timelineId, date, dream, isLucidDream)

		// Redirect to view dream
		if (isUpdated) {
			navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)
		}
	}

	// Cancel dream handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelEntry'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)
		}
	}

	// Delete dream handler
	const handleDelete = () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteDream'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteEntry(timelineId)
						if (response) {
							navigate(commonSettings.apps.web.paths.journal)
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	return (
		<ContentWrapper className="DreamEdit">
			<div className="DreamAdd_Wrapper">
				<div className="DreamEdit_Content_TopBar">
					<Button color="secondary" size="sm" icon="arrow_back" iconFill="1" onClick={handleCancel} text={t('button:journal')} />

					<div>
						<Button
							color="danger"
							size="sm"
							disabled={isSubmitting || journalStore.loading}
							text={t('button:delete')}
							onClick={handleDelete}
						/>
						<Button size="sm" loading={isSubmitting || journalStore.loading} onClick={() => handleSubmit(onSubmit)()} text={t('button:save')} />
					</div>
				</div>

				<H2 isCentered={false}>{t('journal:editDreamHeader')}</H2>

				<Form className="DreamEdit_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="DreamAdd_Content_DreamInfo">
						<Controller
							control={control}
							name="date"
							render={({ field }) => {
								const { value, onChange, ref, ...rest } = field
								return (
									<DateSelector
										customInput={
											<CustomDateSelector
												icon="event"
												className="DreamEdit_Date"
												text={formatDisplayDate(
													languageStore.selectedLanguage?.isoCode + '',
													value,
													DateTime.DATE_MED
												)}
											/>
										}
										dateFormat="Pp"
										innerRef={ref}
										autoComplete="off"
										maxDate={new Date()}
										invalid={Boolean(errors?.date?.message)}
										invalidMessage={errors?.date?.message}
										selected={value}
										onChange={date => onChange(date)}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										{...rest}
									/>
								)
							}}
						/>

						<div className="DreamAdd_DreamOptionsGroup">
							<Controller
								control={control}
								name="isLucidDream"
								render={({ field: { onChange, value, name, ref } }) => (
									<Switch
										className="DreamAdd_Switch"
										values={yesNoSwitchOptions}
										ref={ref}
										name={name}
										label={t('form:isLucidDream.label')}
										showTooltip={true}
										tooltipText={t('form:isLucidDream.helper')}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</div>
					</div>

					<FormGroup>
						{journalStore.error && <p className="DreamEdit_Form_Error">{journalStore.error}</p>}

						<TextArea
							resize="vertical"
							placeholder={t('form:dream.placeholder')}
							invalid={Boolean(errors.dream?.message)}
							invalidMessage={errors.dream?.message}
							{...register('dream')}
						/>
					</FormGroup>
				</Form>
			</div>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(DreamEdit)
