import { useEffect, useState } from 'react'
import './Profile.scss'

// Config
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import Hr from '../../../ruya-shared/shared/ui/atoms/hr/Hr'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Store
import useUserStore from '../../../store/userStore'

// React Hook Form
import { useForm } from 'react-hook-form'

// Types
import type { IUserInfo } from '../../../ruya-shared/shared/types'

const Profile = () => {
	// Translation Hook
	const { t } = useTranslation()

	const userStore = useUserStore()

	const [showUserInfo, setShowUserInfo] = useState(false)
	const [userInfo, setUserInfo] = useState<IUserInfo[]>([])

	// Get questions from translation
	const questions = [
		{
			id: t('userInfo:languagesSpoken.questionId'),
			label: t('userInfo:languagesSpoken.label'),
			placeholder: t('userInfo:languagesSpoken.question'),
			tooltipText: t('userInfo:languagesSpoken.question')
		},
		{
			id: t('userInfo:educationalBackground.questionId'),
			label: t('userInfo:educationalBackground.label'),
			placeholder: t('userInfo:educationalBackground.question'),
			tooltipText: t('userInfo:educationalBackground.question')
		},
		{
			id: t('userInfo:familyBackground.questionId'),
			label: t('userInfo:familyBackground.label'),
			placeholder: t('userInfo:familyBackground.question'),
			tooltipText: t('userInfo:familyBackground.question')
		},
		{
			id: t('userInfo:lifeGoals.questionId'),
			label: t('userInfo:lifeGoals.label'),
			placeholder: t('userInfo:lifeGoals.question'),
			tooltipText: t('userInfo:lifeGoals.question')
		},
		{
			id: t('userInfo:strengthsAndWeaknesses.questionId'),
			label: t('userInfo:strengthsAndWeaknesses.label'),
			placeholder: t('userInfo:strengthsAndWeaknesses.question'),
			tooltipText: t('userInfo:strengthsAndWeaknesses.question')
		},
		{
			id: t('userInfo:phobias.questionId'),
			label: t('userInfo:phobias.label'),
			placeholder: t('userInfo:phobias.question'),
			tooltipText: t('userInfo:phobias.question')
		},
		{
			id: t('userInfo:traumas.questionId'),
			label: t('userInfo:traumas.label'),
			placeholder: t('userInfo:traumas.question'),
			tooltipText: t('userInfo:traumas.question')
		},
		{
			id: t('userInfo:gender.questionId'),
			label: t('userInfo:gender.label'),
			placeholder: t('userInfo:gender.question'),
			tooltipText: t('userInfo:gender.question')
		},
		{
			id: t('userInfo:sexualOrientation.questionId'),
			label: t('userInfo:sexualOrientation.label'),
			placeholder: t('userInfo:sexualOrientation.question'),
			tooltipText: t('userInfo:sexualOrientation.question')
		},
		{
			id: t('userInfo:relationship.questionId'),
			label: t('userInfo:relationship.label'),
			placeholder: t('userInfo:relationship.question'),
			tooltipText: t('userInfo:relationship.question')
		},
		{
			id: t('userInfo:occupation.questionId'),
			label: t('userInfo:occupation.label'),
			placeholder: t('userInfo:occupation.question'),
			tooltipText: t('userInfo:occupation.question')
		},
		{
			id: t('userInfo:culturalBackground.questionId'),
			label: t('userInfo:culturalBackground.label'),
			placeholder: t('userInfo:culturalBackground.question'),
			tooltipText: t('userInfo:culturalBackground.question')
		},
		{
			id: t('userInfo:hobbiesAndInterests.questionId'),
			label: t('userInfo:hobbiesAndInterests.label'),
			placeholder: t('userInfo:hobbiesAndInterests.question'),
			tooltipText: t('userInfo:hobbiesAndInterests.question')
		},
		{
			id: t('userInfo:favoriteActivities.questionId'),
			label: t('userInfo:favoriteActivities.label'),
			placeholder: t('userInfo:favoriteActivities.question'),
			tooltipText: t('userInfo:favoriteActivities.question')
		},
		{
			id: t('userInfo:skillsAndTalents.questionId'),
			label: t('userInfo:skillsAndTalents.label'),
			placeholder: t('userInfo:skillsAndTalents.question'),
			tooltipText: t('userInfo:skillsAndTalents.question')
		},
		{
			id: t('userInfo:personalValues.questionId'),
			label: t('userInfo:personalValues.label'),
			placeholder: t('userInfo:personalValues.question'),
			tooltipText: t('userInfo:personalValues.question')
		},
		{
			id: t('userInfo:spiritualBeliefs.questionId'),
			label: t('userInfo:spiritualBeliefs.label'),
			placeholder: t('userInfo:spiritualBeliefs.question'),
			tooltipText: t('userInfo:spiritualBeliefs.question')
		},
		{
			id: t('userInfo:politicalView.questionId'),
			label: t('userInfo:politicalView.label'),
			placeholder: t('userInfo:politicalView.question'),
			tooltipText: t('userInfo:politicalView.question')
		},
		{
			id: t('userInfo:healthConditions.questionId'),
			label: t('userInfo:healthConditions.label'),
			placeholder: t('userInfo:healthConditions.question'),
			tooltipText: t('userInfo:healthConditions.question')
		},
		{
			id: t('userInfo:medications.questionId'),
			label: t('userInfo:medications.label'),
			placeholder: t('userInfo:medications.question'),
			tooltipText: t('userInfo:medications.question')
		},
		{
			id: t('userInfo:recreationalDrugUse.questionId'),
			label: t('userInfo:recreationalDrugUse.label'),
			placeholder: t('userInfo:recreationalDrugUse.question'),
			tooltipText: t('userInfo:recreationalDrugUse.question')
		},
		{
			id: t('userInfo:notes.questionId'),
			label: t('userInfo:notes.label'),
			placeholder: t('userInfo:notes.question'),
			tooltipText: t('userInfo:notes.question')
		}
	]

	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm() // Form

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (userInfo?.length > 0) {
			// Loop 1-23 and set default values in object
			const defaultValues: { [key: string]: string } = {}

			for (let i = 1; i <= 23; i++) {
				const questionId = `q${i}`
				const answer = userInfo.find(info => info.questionId === questionId)?.answer || ''
				if (answer) defaultValues[questionId] = answer
			}

			// Update default values
			reset(defaultValues)
		}
	}, [userInfo])

	// Form submit
	const onSubmit = async (values: any) => {
		const result = Object.keys(values)
			.filter(questionId => values[questionId].trim() !== '') // Filter out empty answers
			.map(questionId => ({
				questionId,
				question: questions.find(q => q.id === questionId)?.placeholder || '',
				answer: values[questionId]
			}))

		// Save user info
		await userStore.updateUserInfoList(result)

		// Reset form
		setUserInfo([])
		setShowUserInfo(false)
	}

	// Reset form
	const resetForm = () => {
		// Create an object with all question ids set to empty string
		const emptyValues: { [key: string]: string } = questions.reduce(
			(acc, question) => {
				acc[question.id] = ''
				return acc
			},
			{} as { [key: string]: string }
		)

		reset(emptyValues)
	}

	const toggleUserInfo = async () => {
		// Get user info
		const userInfo = (await userStore.getUserInfoList()) || []

		setUserInfo(userInfo)

		setShowUserInfo(!showUserInfo)
	}

	return (
		<ContentWrapper className="Profile">
			<div className="Profile_Wrapper">

				<H2>{t('profile:header')}</H2>

				<P className="Profile_Desc">{t('profile:desc')}</P>

				<P className="Profile_AccountInfo" size="lg"> {t('profile:accountHeader')}</P>

				<div className="Profile_AccountForm">
					<FormGroup twoColumn={true}>
						<Input
							type="text"
							placeholder={t('form:displayName.placeholder')}
							label={t('form:displayName.label')}
							autoComplete="nickname"
							value={userStore.user?.displayName}
							disabled={true}
						/>
						<Input
							type="text"
							placeholder={t('form:email.placeholder')}
							label={t('form:email.label')}
							value={userStore.user?.email}
							autoComplete="off"
							disabled={true}
						/>
					</FormGroup>
				</div>

				<Hr />

				<div className="Profile_Wrapper_Info">
					<P size="lg">{t('profile:infoHeader')}</P>
					<P size="sm">{t('profile:infoDesc')}</P>

					<InfoBox
						icon="security"
						type="info"
						header={t('profile:infoSecurityTitle')}
						text={t('profile:infoSecurityDesc')}
					/>

					{!showUserInfo && (
						<div className="Profile_Wrapper_Info_ShowQuestions">
							<Button text={t('button:showPrivateInformation')} onClick={toggleUserInfo} />
						</div>
					)}

					{showUserInfo && (
						<Form className="Profile_Info_Questions" onSubmit={handleSubmit(onSubmit)} noValidate>
							{
								// Create form elements for each question
								questions.map(question => (
									<FormGroup key={question.id}>
										<TextArea
											label={question.label}
											placeholder={question.placeholder}
											showTooltip={true}
											tooltipText={question.tooltipText}
											{...register(question.id)}
										/>
									</FormGroup>
								))
							}
							<FormGroup twoColumn={true}>
								<Button color="secondary" text={t('button:clear')} onClick={resetForm} />
								<Button type="submit" text={t('button:saveChanges')} />
							</FormGroup>
						</Form>
					)}
				</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(Profile)
