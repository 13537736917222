import './DiaryAdd.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import DateSelector, { CustomDateSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'

// Store
import useJournalStore from '../../../store/journalStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'
import useLanguageStore from '../../../store/languageStore'
import { DateTime } from 'luxon'
import { addDiarySchema } from '../../../validation/diarySchema'

const DiaryAdd = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// React Hook Form
	const initialDiary = {
		content: '',
		date: new Date()
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialDiary,
		mode: 'onChange' as const,
		resolver: yupResolver(addDiarySchema)
	}
	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { content, date } = values

		// Add diary
		const timelineId = await journalStore.addDiary(content, date)

		// Redirect to view Diary
		if (timelineId) {
			navigate(`${commonSettings.apps.web.paths.journal}/diary/${timelineId}`)
		}
	}

	// Cancel diary handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelEntry'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(commonSettings.apps.web.paths.journal)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}

	return (
		<ContentWrapper className="DiaryAdd">
			<div className="DiaryAdd_Wrapper">
				<div className="DiaryAdd_Content_TopBar">
					<Button color="secondary" size="sm" icon="arrow_back" iconFill="1" onClick={handleCancel} text={t('button:journal')} />

					<div>
						<Button size="sm" onClick={() => handleSubmit(onSubmit)()} text={t('button:save')} />
					</div>
				</div>

				<H2 isCentered={false}>{t('journal:newDiaryHeader')}</H2>

				<Form className="DiaryAdd_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="DiaryAdd_Content_Date">
						<Controller
							control={control}
							name="date"
							render={({ field }) => {
								const { value, onChange, ref, ...rest } = field
								return (
									<DateSelector
										customInput={
											<CustomDateSelector
												icon="calendar_month"
												className="DiaryAdd_Date"
												text={formatDisplayDate(
													languageStore.selectedLanguage?.isoCode + '',
													value,
													DateTime.DATE_MED
												)}
											/>
										}
										dateFormat="Pp"
										innerRef={ref}
										autoComplete="off"
										maxDate={new Date()}
										invalid={Boolean(errors?.date?.message)}
										invalidMessage={errors?.date?.message}
										selected={value}
										onChange={date => onChange(date)}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										{...rest}
									/>
								)
							}}
						/>
					</div>
					<FormGroup>
						<TextArea
							resize="vertical"
							placeholder={t('form:diary.placeholder')}
							invalid={Boolean(errors.content?.message)}
							invalidMessage={errors.content?.message}
							{...register('content')}
						/>
					</FormGroup>

					{journalStore.error && <p className="DiaryAdd_Form_Error">{journalStore.error}</p>}
				</Form>
			</div>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(DiaryAdd)
