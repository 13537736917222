import { useState, useEffect, useRef } from 'react'
import './DreamQA.scss'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import TypingDots from '../../../ruya-shared/shared/ui/atoms/typingDots/TypingDots'

// Store
import useJournalStore from '../../../store/journalStore'

// Types
import type { IDreamQA, IInterpretation, IInterpreter } from '../../../ruya-shared/shared/types'

// Localization
import { useTranslation } from 'react-i18next'

interface DreamQAProps {
	interpreter: IInterpreter
	interpretation: IInterpretation | undefined
	dream: string | undefined
	handleAnswer: (answer: string) => void
}

const DreamQA = (props: DreamQAProps) => {
	// Destructure props
	const { interpreter, interpretation, dream, handleAnswer } = props

	// Store
	const journalStore = useJournalStore()

	// Translation Hooks
	const { t } = useTranslation()

	// Implement chat like UI for Dream QA
	const [dreamQA, setDreamQA] = useState<IDreamQA[]>([])
	const [answerInput, setAnswerInput] = useState<string>('')

	// Chat container ref
	const chatContainerRef = useRef<HTMLDivElement | null>(null)

	// Load Dream QA
	useEffect(() => {
		if (interpretation) {
			setDreamQA(interpretation.dreamQA)
		}
	}, [interpretation])

	// Scroll to bottom
	const scrollToBottom = (behavior: ScrollBehavior | undefined) => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTo({
				top: chatContainerRef.current.scrollHeight,
				behavior
			})
		}
	}

	// Scroll to the bottom when the component mounts or messages update
	useEffect(() => {
		// Defer scrolling until the messages have rendered
		setTimeout(() => {
			scrollToBottom('smooth')
		}, 0)
	}, [dreamQA])

	// Also trigger the scroll on the initial page load
	useEffect(() => {
		setTimeout(() => {
			scrollToBottom('instant')
		}, 0)
	}, [])

	// Handle answer
	const handleAnswerClick = () => {
		// Send answer
		handleAnswer(answerInput ? answerInput : t('journal:skipThisQuestion'))
		// Clear input
		setAnswerInput('')
		// Scroll to bottom
		setTimeout(() => {
			scrollToBottom('smooth')
		}, 0)
	}

	// Render Dream QA
	return (
		<div className="DreamQA">
			<div className="DreamQA_InnerContainer">
				<div className="DreamQA_InnerScroll" ref={chatContainerRef}>
					<div>
					<div className="DreamQA_Chat">
						<div className="DreamQA_Chat_Dream">{dream}</div>
						{dreamQA.map((qa: IDreamQA, index: number) => {
							return (
								<div key={index} className={`DreamQA_Chat_Item DreamQA_Chat_Item--${qa.sender}`}>
									{qa.sender !== 'user' && <MaterialSymbol name={interpreter.icon} fill="1" />}
									<div className="DreamQA_Chat_Item_Message">{qa.message}</div>
									{qa.sender === 'user' && <MaterialSymbol name="person" fill="1" />}
								</div>
							)
						})}
						{journalStore.loadingInterpretation && (
							<div className="DreamQA_Chat_Typing">
								<TypingDots />
							</div>
						)}
					</div>
					</div>
				</div>

				<div className="DreamQA_Input">
					<Input
						placeholder={t('journal:typeYourAnswerHere')}
						value={answerInput}
						onChange={e => setAnswerInput(e.target.value)}
						onKeyDown={e => {
							if (e.key === 'Enter') handleAnswerClick()
						}}
					/>

					<Button
						size="sm"
						loading={journalStore.loadingInterpretation}
						disabled={journalStore.loadingInterpretation}
						onClick={handleAnswerClick}
						icon="arrow_upward"
					/>
				</div>
			</div>
		</div>
	)
}

export default DreamQA
