import './Hr.scss'

interface HrProps {
	className?: string
}

const Hr = (props: HrProps) => {
	// Props
	const { className } = props

	const classNames = `Hr${className ? ' ' + className : ''}`

	return <hr className={classNames} />
}

export default Hr
