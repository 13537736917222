import { useEffect, useState } from 'react'
import './EventEdit.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import DateSelector, { CustomDateSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Molecules
import EventSelector from '../../molecules/eventSelector/EventSelector'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import eventOptions from '../../../ruya-shared/shared/config/eventOptions'

// Utils
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'
import { DateTime } from 'luxon'

// Validation schema
import { updateEventSchema } from '../../../validation/eventSchema'

const EventEdit = () => {
	// Translation Hooks
	const { t } = useTranslation()

	const defaultItem = eventOptions[0]
	const customItem = eventOptions[eventOptions.length - 1] // Last item of the array is the custom item

	// Router
	const { timelineId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// Local state
	const [selectedOption, setSelectedOption] = useState(defaultItem)
	const [isCustomSelected, setIsCustomSelected] = useState(false)

	// Load entry
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		}
	}, [timelineId])

	useEffect(() => {
		if (selectedOption.icon === customItem.icon) {
			setIsCustomSelected(true)
		} else {
			setIsCustomSelected(false)
		}
	}, [selectedOption])

	// React Hook Form
	const initialEdit = {
		date: new Date(),
		title: '',
		content: '',
		icon: ''
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialEdit,
		mode: 'onChange' as const,
		resolver: yupResolver(updateEventSchema)
	}

	const {
		register,
		handleSubmit,
		setValue,
		control,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	// Load event if timeline id is provided
	useEffect(() => {
		if (journalStore.currentEntry && timelineId) {
			const { entry, date } = journalStore.currentEntry

			if (entry) {
				setSelectedOption({ name: entry.title, icon: entry.icon })

				reset({
					date: date ? new Date(date) : new Date(),
					title: entry?.title || '',
					content: entry.content || '',
					icon: entry.icon || ''
				})

				if (entry.icon === customItem.icon) {
					setIsCustomSelected(true)
				} else {
					setIsCustomSelected(false)
				}
			}
		}
	}, [journalStore.currentEntry, timelineId, reset])

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { date, title, content, icon } = values

		// Add dream
		const isUpdated = await journalStore.updateEvent(timelineId, date, title, content, icon)

		// Redirect to view dream
		if (isUpdated) {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}

	// Cancel dream handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelEntry'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(commonSettings.apps.web.paths.journal)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}

	// Delete dream handler
	const handleDelete = () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteEvent'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						const response = await journalStore.deleteEntry(timelineId)
						if (response) {
							navigate(commonSettings.apps.web.paths.journal)
						}
					}
				},
				{
					label: t('button:no')
				}
			]
		})
	}

	const handleChange = (selectedName: string) => {
		const selectedItem = eventOptions.find(option => option.name === selectedName)
		if (selectedItem) {
			setSelectedOption(selectedItem)

			if (selectedName === customItem.name) {
				setValue('title', journalStore.currentEntry.entry.title || '')
				setValue('icon', customItem.icon)
			} else {
				setValue('title', selectedItem.name)
				setValue('icon', selectedItem.icon)
			}
		}
	}

	return (
		<ContentWrapper className="EventEdit">
			<div className="EventEdit_Wrapper">
				<div className="EventEdit_Content_TopBar">
					<Button color="secondary" size="sm" icon="arrow_back" iconFill="1" onClick={handleCancel} text={t('button:journal')} />

					<div className="EventEdit_Content_TopBar_Buttons">
						<Button color="danger" size="sm" disabled={isSubmitting || journalStore.loading} text={t('button:delete')} onClick={handleDelete} />

						<Button
							size="sm"
							disabled={isSubmitting || journalStore.loading}
							loading={isSubmitting || journalStore.loading}
							onClick={() => handleSubmit(onSubmit)()}
							text={t('button:save')}
						/>

						{journalStore.error && (
							<div className="EventEdit_Error">
								<MaterialSymbol name="emergency_home" fill="1" />
								<span>{journalStore.error}</span>
							</div>
						)}
					</div>
				</div>

				<H2>{t('journal:editEventHeader')}</H2>

				<Form className={`EventEdit_Form${isCustomSelected ? ' EventEdit_Form--custom' : ''}`} onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="EventEdit_Content_Details">
						<div>
							<EventSelector selectedOption={selectedOption} onChange={handleChange} />
							<Input type="hidden" {...register('icon')} />
						</div>

						<div className="EventEdit_Content_Date">
							<Controller
								control={control}
								name="date"
								render={({ field }) => {
									const { value, onChange, ref, ...rest } = field
									return (
										<DateSelector
											customInput={
												<CustomDateSelector
													icon="calendar_month"
													className="EventEdit_Date"
													text={formatDisplayDate(languageStore.selectedLanguage?.isoCode + '', value, DateTime.DATE_MED)}
												/>
											}
											dateFormat="Pp"
											innerRef={ref}
											autoComplete="off"
											maxDate={new Date()}
											invalid={Boolean(errors?.date?.message)}
											invalidMessage={errors?.date?.message}
											selected={value}
											onChange={date => onChange(date)}
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											{...rest}
										/>
									)
								}}
							/>
						</div>
					</div>

					{isCustomSelected && (
						<Input
							placeholder={t('form:eventTitle.placeholder')}
							invalid={Boolean(errors.title?.message)}
							invalidMessage={errors.title?.message}
							{...register('title')}
						/>
					)}

					<FormGroup>
						<TextArea
							resize="vertical"
							placeholder={t('form:event.placeholder')}
							invalid={Boolean(errors.content?.message)}
							invalidMessage={errors.content?.message}
							{...register('content')}
						/>
					</FormGroup>
				</Form>
			</div>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(EventEdit)
