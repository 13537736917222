import { useEffect, useRef } from 'react'
import './SecurityChallenge.scss'

// Cloudflare Turnstile
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

// Utils
import { getSupportedTurnstileLanguage } from '../../../ruya-shared/shared/utils/commonHelper'

// Router
import { useLocation, useParams } from 'react-router-dom'
import useLanguageStore from '../../../store/languageStore'

declare global {
	interface Window {
		ReactNativeWebView?: {
			postMessage(message: any): void
		}
	}
}

const SecurityChallenge = () => {
	const captchaTokenRef = useRef<TurnstileInstance>(null)

	const languageStore = useLanguageStore()

	// Router
	const location = useLocation()
	const { lang } = useParams()
	const queryParams = new URLSearchParams(location.search)

	useEffect(() => {
		// Add a unique class to the body when the component mounts
		languageStore.setLanguage(lang || languageSettings.defaultLanguage)
		document.body.classList.add('SecurityChallenge')

		// Clean up: remove the class when the component unmounts
		return () => {
			document.body.classList.remove('SecurityChallenge')
		}
	}, [])

	// Set the language
	const language = getSupportedTurnstileLanguage(lang || languageSettings.defaultLanguage) || undefined

	// Action and cData
	const action = queryParams.get('action') || undefined
	const cData = queryParams.get('cData') || undefined

	// Narrow theme to allowed values: "light", "dark", or "auto"
	const themeParam = queryParams.get('theme')
	const theme: 'light' | 'dark' | 'auto' | undefined =
		themeParam === 'light' || themeParam === 'dark' || themeParam === 'auto' ? themeParam : undefined

	// Tab index conversion
	const tabIndex = queryParams.get('tabIndex') ? Number.parseInt(queryParams.get('tabIndex') as string, 10) || 0 : 0

	// Boolean fields
	const responseField = queryParams.get('responseField') !== null ? queryParams.get('responseField') === 'true' : undefined
	const responseFieldName = queryParams.get('responseFieldName') || undefined

	// Narrow size to allowed values: "normal" or "compact"
	const sizeParam = queryParams.get('size')
	const widgetSize: 'normal' | 'compact' = sizeParam === 'compact' ? 'compact' : 'normal'

	// Fixed size conversion
	const fixedSize = queryParams.get('fixedSize') !== null ? queryParams.get('fixedSize') === 'true' : undefined

	// Narrow retry to allowed values: "auto" or "never"
	const retryParam = queryParams.get('retry')
	const retry: 'auto' | 'never' | undefined = retryParam === 'auto' || retryParam === 'never' ? retryParam : undefined

	// Convert retryInterval to number
	const retryInterval = queryParams.get('retryInterval') ? Number.parseInt(queryParams.get('retryInterval') as string, 10) : undefined

	// Narrow refreshExpired to allowed values: "auto" | "manual" | "never"
	const refreshExpiredParam = queryParams.get('refreshExpired')
	const refreshExpired: 'auto' | 'manual' | 'never' | undefined =
		refreshExpiredParam === 'auto' || refreshExpiredParam === 'manual' || refreshExpiredParam === 'never'
			? refreshExpiredParam
			: undefined

	// Narrow appearance to allowed values: "always" | "execute" | "interaction-only"
	const appearanceParam = queryParams.get('appearance')
	const appearance: 'always' | 'execute' | 'interaction-only' | undefined =
		appearanceParam === 'always' || appearanceParam === 'execute' || appearanceParam === 'interaction-only'
			? appearanceParam
			: undefined

	// Narrow execution to allowed values: "render" | "execute"
	const executionParam = queryParams.get('execution')
	const execution: 'render' | 'execute' | undefined =
		executionParam === 'render' || executionParam === 'execute' ? executionParam : undefined

	const id = queryParams.get('id') || undefined

	// Build the options object for Turnstile
	const options = {
		tabIndex: tabIndex,
		language: language,
		...(action && { action }),
		...(cData && { cData }),
		...(theme && { theme }),
		...(responseField !== undefined && { responseField }),
		...(responseFieldName && { responseFieldName }),
		size: widgetSize,
		...(fixedSize !== undefined && { fixedSize }),
		...(retry && { retry }),
		...(retryInterval !== undefined && { retryInterval }),
		...(refreshExpired && { refreshExpired }),
		...(appearance && { appearance }),
		...(execution && { execution }),
		...(id && { id })
	}

	// Called when the captcha is successfully solved
	const handleSuccess = (token: string) => {
		// If this page is loaded inside a React Native WebView, send the token back via postMessage
		if (window.ReactNativeWebView?.postMessage) {
			window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'verify', data: token }))
		}
	}

	// Called on error: reset the widget
	const handleError = (error?: any) => {
		console.error('Turnstile error:', error)
		captchaTokenRef.current?.reset()
	}

	return (
		<Turnstile
			ref={captchaTokenRef}
			siteKey={commonSettings.cloudFlare.turnstile.turnstileSiteKey}
			scriptOptions={{ async: true, defer: true }}
			options={options}
			onError={handleError}
			onSuccess={handleSuccess}
		/>
	)
}

export default SecurityChallenge
