import './TimelineMenu.scss'
import { useEffect, useState } from 'react'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import SearchBox from '../../../ruya-shared/shared/ui/atoms/searchBox/SearchBox'
import RadioBox from '../../../ruya-shared/shared/ui/atoms/radioBox/RadioBox'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useUserStore from '../../../store/userStore'

// Utils
import { parseDate } from '../../../ruya-shared/shared/utils/dateHelper'
import { DateTime } from 'luxon'

const TimelineMenu = () => {
	// Local State
	const [filtersOpen, setFiltersOpen] = useState(false)
	const [years, setYears] = useState<number[]>([])

	// Store
	const journalStore = useJournalStore()
	const userStore = useUserStore()

	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	useEffect(() => {
		// Loop years from register date to today.
		const registerYear = parseDate(userStore.user?.createdAt, 'luxon').year
		const currentYear = new Date().getFullYear()
		const yearsArray = Array.from({ length: currentYear - registerYear + 1 }, (_, i) => currentYear - i)
		setYears(yearsArray)
	}, [])

	// Toggle filters form
	const toggleFilters = () => {
		setFiltersOpen(!filtersOpen)
	}

	// Apply filters
	const applyFilters = async () => {
		await journalStore.loadTimeline()
	}

	// Reset filters
	const resetFilters = async () => {
		await journalStore.loadTimeline(true)
		setFiltersOpen(false)
	}

	// Handle type change
	const handleTypeChange = (type: string) => {
		useJournalStore.setState(state => ({
			filters: { ...state.filters, entryType: type }
		}))
	}

	// Handle year change
	const handleYearChange = (year: string) => {
		useJournalStore.setState(state => ({
			filters: { ...state.filters, entryYear: year, entryMonth: 'all' }
		}))
	}

	// Handle month change
	const handleMonthChange = (month: string) => {
		useJournalStore.setState(state => ({
			filters: { ...state.filters, entryMonth: month }
		}))
	}

	// Is month greater than current month and year is current year or all
	const isMonthDisabled = (month : number) => {
		if(journalStore.filters.entryYear === DateTime.now().year.toString() || journalStore.filters.entryYear === 'all') {
			if (month > DateTime.now().month) {
				return true
			}
		}
		return false
	}

	if (journalStore.timelineData.welcome) return null

	return (
		<div className="TimelineMenu">
			<div className="TimelineMenu_Buttons">
				<Button
					size="sm"
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/diary/add`)}
					icon="history_edu"
					text={t('button:newDiary')}
					iconFill="1"
				/>

				<Button
					size="sm"
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/add`)}
					icon="partly_cloudy_night"
					text={t('button:newDream')}
					iconFill="1"
				/>

				<Button
					size="sm"
					onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/event/add`)}
					icon="event"
					text={t('button:addLifeEvent')}
					iconFill="1"
				/>

				<Button
					color="secondary"
					size="sm"
					onClick={toggleFilters}
					text={filtersOpen ? t('button:hideFilters') : t('button:showFilters')}
					icon="filter_list"
				/>
			</div>

			<div className={`TimelineMenu_Filters ${filtersOpen ? 'TimelineMenu_Filters--open' : 'TimelineMenu_Filters--closed'}`}>
				<div className="TimelineMenu_Filters_Form">
					<div className="TimelineMenu_Filters_Item">
						<SearchBox
							onChange={e =>
								useJournalStore.setState(state => ({
									filters: { ...state.filters, keyword: e.target.value }
								}))
							}
							value={journalStore.filters.keyword}
						/>
					</div>

					<div className="TimelineMenu_Filters_Item">
						<Switch
							labelPosition="left"
							values={{
								on: { value: true, label: t('form:journalSearchFavorite:on') },
								off: { value: false, label: t('form:journalSearchFavorite:off') }
							}}
							onChange={e =>
								useJournalStore.setState({ filters: { ...journalStore.filters, favorite: e.target.value?.toString() } })
							}
							value={journalStore.filters.favorite === 'true'}
						/>
					</div>

					<div className="TimelineMenu_Filters_Item">
						<span className="TimelineMenu_Filters_Item_Title">{t('form:journalEntryType.label')}</span>
						<RadioBox
							label={t('form:journalEntryType.placeholder')}
							name="type"
							value="all"
							checked={journalStore.filters.entryType === 'all'}
							onChange={() => handleTypeChange('all')}
						/>
						<RadioBox
							label={t('options:diary')}
							name="type"
							value="diary"
							checked={journalStore.filters.entryType === 'diary'}
							onChange={() => handleTypeChange('diary')}
						/>
						<RadioBox
							label={t('options:dream')}
							name="type"
							value="dream"
							checked={journalStore.filters.entryType === 'dream'}
							onChange={() => handleTypeChange('dream')}
						/>
							<RadioBox
							label={t('options:event')}
							name="type"
							value="dream"
							checked={journalStore.filters.entryType === 'event'}
							onChange={() => handleTypeChange('event')}
						/>
					</div>

					<div className="TimelineMenu_Filters_Item">
						<span className="TimelineMenu_Filters_Item_Title">{t('form:journalSearchYear.label')}</span>
						<RadioBox
							label={t('form:journalSearchYear.placeholder')}
							name="year"
							value="all"
							checked={journalStore.filters.entryYear === 'all'}
							onChange={() => handleYearChange('all')}
						/>
						{years.map(year => (
							<RadioBox
								key={'year' + year.toString()}
								label={t('options:' + year.toString())}
								name="year"
								value={year.toString()}
								onChange={() => handleYearChange(year.toString())}
								checked={journalStore.filters.entryYear === year.toString()}
							/>
						))}
					</div>

					<div className="TimelineMenu_Filters_Item">
						<span className="TimelineMenu_Filters_Item_Title">{t('form:journalSearchMonth.label')}</span>
						<RadioBox
							label={t('form:journalSearchMonth.placeholder')}
							name="month"
							value="all"
							checked={journalStore.filters.entryMonth === 'all'}
							onChange={() => handleMonthChange('all')}
						/>
						<RadioBox
							label={t('options:january')}
							name="month"
							value="1"
							checked={journalStore.filters.entryMonth === '1'}
							onChange={() => handleMonthChange('1')}
							disabled={isMonthDisabled(1)}
						/>
						<RadioBox
							label={t('options:february')}
							name="month"
							value="2"
							checked={journalStore.filters.entryMonth === '2'}
							onChange={() => handleMonthChange('2')}
							disabled={isMonthDisabled(2)}
						/>
						<RadioBox
							label={t('options:march')}
							name="month"
							value="3"
							checked={journalStore.filters.entryMonth === '3'}
							onChange={() => handleMonthChange('3')}
							disabled={isMonthDisabled(3)}
						/>
						<RadioBox
							label={t('options:april')}
							name="month"
							value="4"
							checked={journalStore.filters.entryMonth === '4'}
							onChange={() => handleMonthChange('4')}
							disabled={isMonthDisabled(4)}
						/>
						<RadioBox
							label={t('options:may')}
							name="month"
							value="5"
							checked={journalStore.filters.entryMonth === '5'}
							onChange={() => handleMonthChange('5')}
							disabled={isMonthDisabled(5)}
						/>
						<RadioBox
							label={t('options:june')}
							name="month"
							value="6"
							checked={journalStore.filters.entryMonth === '6'}
							onChange={() => handleMonthChange('6')}
							disabled={isMonthDisabled(6)}
						/>
						<RadioBox
							label={t('options:july')}
							name="month"
							value="7"
							checked={journalStore.filters.entryMonth === '7'}
							onChange={() => handleMonthChange('7')}
							disabled={isMonthDisabled(7)}
						/>
						<RadioBox
							label={t('options:august')}
							name="month"
							value="8"
							checked={journalStore.filters.entryMonth === '8'}
							onChange={() => handleMonthChange('8')}
							disabled={isMonthDisabled(8)}
						/>
						<RadioBox
							label={t('options:september')}
							name="month"
							value="9"
							checked={journalStore.filters.entryMonth === '9'}
							onChange={() => handleMonthChange('9')}
							disabled={isMonthDisabled(9)}
						/>
						<RadioBox
							label={t('options:october')}
							name="month"
							value="10"
							checked={journalStore.filters.entryMonth === '10'}
							onChange={() => handleMonthChange('10')}
							disabled={isMonthDisabled(10)}
						/>
						<RadioBox
							label={t('options:november')}
							name="month"
							value="11"
							checked={journalStore.filters.entryMonth === '11'}
							onChange={() => handleMonthChange('11')}
							disabled={isMonthDisabled(11)}
						/>
						<RadioBox
							label={t('options:december')}
							name="month"
							value="12"
							checked={journalStore.filters.entryMonth === '12'}
							onChange={() => handleMonthChange('12')}
							disabled={isMonthDisabled(12)}
						/>
					</div>

					<div className="TimelineMenu_Filters_Buttons">
						<Button color="secondary" size="sm" text={t('button:reset')} onClick={resetFilters} />
						<Button color="primary" size="sm" text={t('button:apply')} onClick={applyFilters} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TimelineMenu
