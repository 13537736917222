import { createRef, useEffect, useState } from 'react'
import './InterpreterSelection.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'

// Atoms
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'

// Molecules
import Interpreter from '../../molecules/interpreter/Interpreter'

// Types
import type { IDream, IInterpretation, IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const InterpreterSelection = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { timelineId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()

	// Local state
	const [interpretations, setInterpretations] = useState<IInterpretation[] | null>([])

	// Check parameters
	useEffect(() => {
		// Sleep session Id is required
		if (!timelineId) navigate('/journal')
	}, [timelineId])

	// Load dream interpretations
	useEffect(() => {
		if (timelineId) {
			journalStore.loadTimelineEntry(timelineId)
		}
	}, [timelineId])

	// Load dream interpretations
	useEffect(() => {
		if (journalStore.currentEntry) {
			setInterpretations((journalStore.currentEntry.entry as IDream)?.interpretations || [])
		}
	}, [journalStore.currentEntry])

	// Check if dream is interpreted by the interpreter
	const getInterpretation = (interpreterId: ObjectId) => {
		return interpretations?.find((interpretation: IInterpretation) => interpretation.interpreterId === interpreterId) || null
	}

	// Continue to dream-questionnaire
	const handleInterpret = (interpreterId: ObjectId) => {
		if (!interpreterId) return

		const tmpInterpretation = getInterpretation(interpreterId.toString())

		if (tmpInterpretation?.isDreamQACompleted) {
			navigate(`${commonSettings.apps.web.paths.journal}/dream/interpretation/${timelineId}/${tmpInterpretation.interpreterId?.toString()}`)
		} else {
			navigate(`${commonSettings.apps.web.paths.journal}/dream-questionnaire/${timelineId}/${interpreterId.toString()}`)
		}
	}

	return (
		<ContentWrapper className="InterpreterSelection">
			<Button
				color="secondary"
				size="sm"
				icon="arrow_back"
				iconFill="1"
				text={t('links:back')}
				onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/${timelineId}`)}
			/>

			<H2>{t('journal:interpretHeader')}</H2>

			<P className="InterpreterSelection_Explanation">{t('journal:interpretDescription')}</P>

			<div className="InterpreterSelection_List">
				{interpreterStore.interpreterList.map((interpreter: IInterpreter) => {
					if (!interpreter?._id) return null

					// Get interpretation
					const interpretation = getInterpretation(interpreter._id?.toString())

					// Check if dream is interpreted by the interpreter
					const isInterpreted = interpretation?.isDreamQACompleted || false

					return (
						<div className="InterpreterSelection_List_Item" key={interpreter._id?.toString()}>
							<Interpreter interpreter={interpreter} handleInterpret={handleInterpret} isInterpreted={isInterpreted} />
						</div>
					)
				})}
			</div>

			{journalStore.error && (
				<FormGroup>
					<p className="InterpreterSelection_Error">{journalStore.error}</p>
				</FormGroup>
			)}
		</ContentWrapper>
	)
}

export default Private(InterpreterSelection)
