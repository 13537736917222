import './Languages.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'

// Molecules
import LanguageSelection from '../../molecules/languageSelection/LanguageSelection'

// Template
import Private from '../../templates/private/Private'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

const Languages = () => {
	// Translation Hook
	const { t } = useTranslation()

	return (
		<ContentWrapper className="Languages">
			<div className="Languages_Wrapper">
				<H1 isCentered={true}>{t('languages:header')}</H1>

				<p className="Languages_Desc">{t('languages:explanation', { languageCount: getActiveLanguages?.length })}</p>

				<LanguageSelection navigateTo={commonSettings.apps.web.paths.journal} />
			</div>
		</ContentWrapper>
	)
}

export default Private(Languages)
