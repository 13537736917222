import './TimelineNoRecord.scss'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Store
import useJournalStore from '../../../store/journalStore'

// Localization
import { useTranslation } from 'react-i18next'

const TimelineNoRecord = () => {
	// Store
	const journalStore = useJournalStore()

	// Translation Hook
	const { t } = useTranslation()

	if (journalStore.loading || journalStore.error || journalStore.timelineData.welcome) return null

	if (journalStore.timelineData.list.length === 0) {
		return (
			<div className="TimelineNoRecord">
				<MaterialSymbol name="info" /> <span>{t('info:noRecordsFoundWithFilters')}</span>
			</div>
		)
	}
}

export default TimelineNoRecord
