import { useEffect } from 'react'
import './Activation.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Template
import Public from '../../templates/public/Public'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Zustand
import useUserStore from '../../../store/userStore'

// Atoms
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'


const Activation = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()
	const { token } = useParams()

	// Zustand
	const userStore = useUserStore()

	useEffect(() => {
		// Redirect to dashboard if user authenticated and active
		if (userStore?.user?.isActive) {
			navigate(commonSettings.apps.web.paths.journal, { replace: true })
		}
	}, [userStore.user])

	useEffect(() => {
		if (!token) return
		// Send the token to the server to activate the account
		userStore.activateUser(token).then(() => {
			navigate(commonSettings.apps.web.paths.journal, { replace: true })
		})
	}, [token])

	return (
		<ContentWrapper className="Activation">
			<H1 isCentered={true}>{t('activation:header')}</H1>

			{userStore.loading && <p className="Activation_Desc">{t('activation:loading')}</p>}

			{userStore.error && <p className="Activation_Error">{userStore.error}</p>}
		</ContentWrapper>
	)
}

export default Public(Activation)
