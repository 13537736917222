// Import necessary libraries
import { getCookie as getServerCookie, setCookie as setServerCookie, deleteCookie as deleteServerCookie } from 'cookies-next'
import Cookies from 'js-cookie'

// Types
import type { ICookieOptions } from '../types'

// Universal function to get a cookie
export const getCookie = (name: string, { req }: any = {}): string | undefined => {
	if (typeof window === 'undefined') {
		// Server-side: use cookies-next with the request object
		return getServerCookie(name, { req }) as string | undefined
	}
	// Client-side: use js-cookie
	return Cookies.get(name)
}

// Universal function to write a cookie
export const writeCookie = (name: string, value: string, options: ICookieOptions = {}, req?: any, res?: any) => {
	// Convert number of days to a Date object for expires
	let expiresDate: Date | undefined
	if (typeof options.expires === 'number') {
		expiresDate = new Date()
		expiresDate.setTime(expiresDate.getTime() + options.expires * 86400000) // convert days to milliseconds
	} else if (options.expires instanceof Date) {
		expiresDate = options.expires
	}

	if (typeof window === 'undefined') {
		// Server-side: use cookies-next with the request and response objects
		setServerCookie(name, value, {
			req,
			res,
			expires: expiresDate,
			path: options.path || '/',
			domain: options.domain,
			secure: options.secure,
			httpOnly: options.httpOnly,
			sameSite: options.sameSite || 'lax'
		})
	} else {
		// Client-side: use js-cookie, note that js-cookie does not support httpOnly as it's a server-side flag
		const clientOptions = {
			expires: expiresDate,
			path: options.path || '/',
			domain: options.domain,
			secure: options.secure,
			sameSite: options.sameSite || 'lax'
		}
		Cookies.set(name, value, clientOptions)
		console.log('Cookie set:', name, value, clientOptions)
	}
}

// Universal function to delete a cookie
export const deleteCookie = (name: string, options: ICookieOptions = {}, req?: any, res?: any) => {
	if (typeof window === 'undefined') {
		// Server-side: use cookies-next with the request and response objects
		deleteServerCookie(name, {
			req,
			res,
			path: options.path || '/',
			domain: options.domain
		})
	} else {
		// Client-side: use js-cookie
		Cookies.remove(name, {
			path: options.path || '/',
			domain: options.domain
		})
	}
}
