import './PublicLanguages.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Template
import Public from '../../templates/public/Public'

// Atoms
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'

// Molecules
import LanguageSelection from '../../molecules/languageSelection/LanguageSelection'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Utils
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

const PublicLanguages = () => {
	// Translation Hook
	const { t } = useTranslation()

	return (
		<ContentWrapper className="PublicLanguages">
			<div className="PublicLanguages_Wrapper">
				<H1 isCentered={true}>{t('languages:header')}</H1>

				<p className="PublicLanguages_Desc">{t('languages:explanation', { languageCount: getActiveLanguages?.length })}</p>

				<LanguageSelection navigateTo={commonSettings.apps.web.paths.home} />
			</div>
		</ContentWrapper>
	)
}

export default Public(PublicLanguages)
