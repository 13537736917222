import type { ReactNode } from 'react'
import './Badge.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface BadgeProps {
	className?: string
	color?: string
	icon?: string
	iconFill?: '0' | '1'
	iconPosition?: 'left' | 'right'
	children?: ReactNode
	[key: string]: any
}

const Badge = (props: BadgeProps) => {
	const { className, color, size, shape, children, icon, iconFill = '0', iconPosition = 'left', ...rest } = props

	// Classes
	const classNames =
		'Badge' + `${color ? ` Badge--${color}` : ' Badge--info'}` + `${icon ? ' Badge--hasIcon' : ''}` + `${className !== undefined ? ' ' + className : ''}`

	return (
		<span className={classNames} {...rest}>
			{iconPosition === 'left' && icon && <MaterialSymbol name={icon} fill={iconFill} />}
			<span className="Badge_Text">{children}</span>
			{iconPosition === 'right' && icon && <MaterialSymbol name={icon} fill={iconFill} />}
		</span>
	)
}

export default Badge
