import {
	forwardRef,
	type Ref,
	type ReactNode,
	useEffect,
	useState,
	type HTMLAttributes,
	type ReactElement,
	type SyntheticEvent
} from 'react'
import './DateSelector.scss'

// React datepicker
import DatePicker, { type DatePickerProps } from 'react-datepicker'
import type { Locale } from 'date-fns'

// Localization
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

// Helpers
import { DateTime } from 'luxon'

// Utils
import { localeImports } from '../../../utils/dateFnsLocaleMapping'
import { parseDate } from '../../../utils/dateHelper'
import { generateRandomLetters } from '../../../utils/commonHelper'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'
import Tooltip from '../tooltip/Tooltip'
import Loading from '../loading/Loading'
import Button from '../button/Button'

// Extend DatePickerProps while disallowing multi-/range props.
interface DateSelectorProps extends Omit<DatePickerProps, 'selectsMultiple' | 'selectsRange' | 'onChange'> {
	icon?: string
	label?: string
	name?: string
	customInput?: ReactElement
	showTimeSelect?: boolean
	size?: string
	valid?: boolean
	invalid?: boolean
	invalidMessage?: string
	warning?: boolean
	className?: string
	locale?: string
	dateFormat?: string
	timeFormat?: string
	timeIntervals?: number
	innerRef?: Ref<DatePicker>
	autoComplete?: string
	selected?: Date | null
	showTooltip?: boolean
	tooltipText?: string
	placeholder?: string
	disabled?: boolean
	selectsMultiple?: never // disallow multiple selections
	selectsRange?: never // disallow range selection
	onChange?: (date: DateTime | null) => void
}

// Narrow DatePickerProps to single-date mode (i.e. without multi-/range props)
type SingleDatePickerProps = Extract<DatePickerProps, { selectsMultiple?: undefined; selectsRange?: undefined }>

const DateSelector = (props: DateSelectorProps, innerRef: Ref<DatePicker>): ReactNode => {
	// Translation Hook
	const { t } = useTranslation()
	const [loadedLocale, setLoadedLocale] = useState<Locale | null>(null)

	// Destructure props; extract unwanted keys (selectsMultiple, selectsRange)
	const {
		icon,
		label,
		name = generateRandomLetters(),
		size,
		valid,
		invalid,
		invalidMessage,
		warning,
		className,
		locale = i18next.language,
		dateFormat = 'P',
		selected,
		minDate,
		maxDate,
		onChange = () => {},
		showTooltip = false,
		tooltipText = '',
		placeholder,
		customInput,
		selectsMultiple, // extracted (not forwarded)
		selectsRange, // extracted (not forwarded)
		...rest
	} = props

	// Cast rest to a generic object to remove unwanted keys.
	const restAny = rest as Record<string, unknown>
	const { showMonthYearDropdown, ...restWithoutMonthYear } = restAny

	useEffect(() => {
		localeImports[locale].then((loc: any) => {
			setLoadedLocale(loc)
		})
	}, [locale])

	// Compose CSS classes
	const classNames =
		'DateSelector' +
		`${size ? ' DateSelector--' + size : ''}` +
		`${label ? ' DateSelector--hasLabel' : ''}` +
		`${props.disabled ? ' DateSelector--disabled' : ''}` +
		`${warning ? ' DateSelector--warning' : ''}` +
		`${invalid ? ' DateSelector--invalid' : ''}` +
		`${className !== undefined ? ' ' + className : ''}`

	// onChange handler
	const handleOnChange = (date: Date | null, event?: SyntheticEvent<Element, Event>) => {
		if (date && DateTime.fromJSDate(date).isValid) {
			onChange(DateTime.fromJSDate(date))
		} else {
			onChange(null)
		}
	}

	// Convert dates via your parseDate helper
	let tmpSelected: Date | undefined
	let tmpMinDate: Date | undefined
	let tmpMaxDate: Date | undefined
	if (selected) tmpSelected = parseDate(selected)?.toJSDate()
	if (minDate) tmpMinDate = parseDate(minDate)?.toJSDate()
	if (maxDate) tmpMaxDate = parseDate(maxDate)?.toJSDate()

	if (!loadedLocale) {
		return (
			<div className={classNames}>
				<Loading />
			</div>
		)
	}

	// Build props for DatePicker (ref is passed separately)
	const datePickerProps: SingleDatePickerProps = {
		name,
		locale: loadedLocale,
		dateFormat,
		customInput,
		...(placeholder ? { placeholderText: placeholder } : {}),
		...(tmpMinDate ? { minDate: tmpMinDate } : {}),
		...(tmpMaxDate ? { maxDate: tmpMaxDate } : {}),
		...(tmpSelected ? { selected: tmpSelected } : {}),
		onChange: (date: Date | null, event?: SyntheticEvent<Element, Event>) => handleOnChange(date, event),
		clearButtonClassName: 'DateSelector_ClearButton',
		clearButtonTitle: t('button:clear'),
		...restWithoutMonthYear
	}

	return (
		<div className={classNames}>
			{(label || icon || showTooltip) && (
				<div className="DateSelector_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label htmlFor={name}>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="DateSelector_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}
			<div className="DateSelector_Wrapper">
				<DatePicker ref={innerRef} {...datePickerProps} />
			</div>
			{invalid && invalidMessage && (
				<p className="DateSelector_InvalidMessage">
					<MaterialSymbol name="cancel" fill="1" /> {invalidMessage}
				</p>
			)}
		</div>
	)
}

// Custom date selector
interface CustomDateSelectorProps extends HTMLAttributes<HTMLDivElement> {
	text?: string
	onClick?: () => void
	className?: string
	icon?: string
}
export const CustomDateSelector = forwardRef<HTMLDivElement, CustomDateSelectorProps>((props, ref) => {
	const { onClick, className, text, icon } = props
	return (
		<div className={className} ref={ref}>
			<Button color="secondary" size="sm" icon={icon} iconFill="1" onClick={onClick} text={text} />
		</div>
	)
})

// Custom time selector
interface CustomTimeSelectorProps extends HTMLAttributes<HTMLDivElement> {
	value?: string
	onClick?: () => void
	className?: string
}
export const CustomTimeSelector = forwardRef<HTMLDivElement, CustomTimeSelectorProps>(({ value, onClick, className }, ref) => {
	return (
		<div className={className} onClick={onClick} ref={ref}>
			{value}
		</div>
	)
})
CustomTimeSelector.displayName = 'CustomTimeSelector'

export default forwardRef(DateSelector)
