import { useEffect, useState } from 'react'
import './EventAdd.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import DateSelector, { CustomDateSelector } from '../../../ruya-shared/shared/ui/atoms/dateSelector/DateSelector'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import EventSelector from '../../molecules/eventSelector/EventSelector'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Store
import useJournalStore from '../../../store/journalStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import eventOptions from '../../../ruya-shared/shared/config/eventOptions'

// Utils
import { formatDisplayDate } from '../../../ruya-shared/shared/utils/dateHelper'
import { DateTime } from 'luxon'

// Validation
import { addEventSchema } from '../../../validation/eventSchema'

const EventAdd = () => {
	// Translation Hooks
	const { t } = useTranslation()

	const defaultItem = eventOptions[0]
	const customItem = eventOptions[eventOptions.length - 1] // Last item of the array is the custom item

	// Local state
	const [selectedOption, setSelectedOption] = useState(defaultItem)
	const [isCustomSelected, setIsCustomSelected] = useState(false)

	// Router
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const languageStore = useLanguageStore()

	// React Hook Form
	const initialEvent = {
		date: new Date(),
		title: defaultItem.name,
		content: '',
		icon: defaultItem.icon
	}

	// React Hook Form
	const formOptions = {
		defaultValues: initialEvent,
		mode: 'onChange' as const,
		resolver: yupResolver(addEventSchema)
	}
	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting }
	} = useForm(formOptions) // Form

	useEffect(() => {
		if (selectedOption.icon === customItem.icon) {
			setIsCustomSelected(true)
		} else {
			setIsCustomSelected(false)
		}
	}, [selectedOption])

	const handleChange = (selectedName: string) => {
		const selectedItem = eventOptions.find(option => option.name === selectedName)
		if (selectedItem) {
			setSelectedOption(selectedItem)

			if (selectedName === customItem.name) {
				setValue('title', '')
				setValue('icon', customItem.icon)
			} else {
				setValue('title', selectedItem.name)
				setValue('icon', selectedItem.icon)
			}
		}
	}

	// Form submit
	const onSubmit = async (values: any) => {
		// Destruct values
		const { date, title, content, icon } = values

		// Add Event
		const isUpdated = await journalStore.addEvent(date, title, content, icon)

		// Redirect to view Event
		if (isUpdated) {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}

	// Cancel event handler
	const handleCancel = () => {
		// Show confirmation dialog if form is modified
		if (isDirty) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:cancelEntry'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => navigate(commonSettings.apps.web.paths.journal)
					},
					{
						label: t('button:no')
					}
				]
			})
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}

	return (
		<ContentWrapper className="EventAdd">
			<div className="EventAdd_Wrapper">
				<div className="EventAdd_Content_TopBar">
					<Button color="secondary" size="sm" icon="arrow_back" iconFill="1" onClick={handleCancel} text={t('button:journal')} />

					<div className="EventAdd_Content_TopBar_Buttons">
						<Button size="sm" onClick={() => handleSubmit(onSubmit)()} text={t('button:save')} />
						{journalStore.error && (
							<div className="EventAdd_Error">
								<MaterialSymbol name="emergency_home" fill="1" />
								<span>{journalStore.error}</span>
							</div>
						)}
					</div>
				</div>

				<H2>{t('journal:newEventHeader')}</H2>

				<Form className={`EventAdd_Form${isCustomSelected ? ' EventAdd_Form--custom': ''}`} onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="EventAdd_Content_Details">

						<div>
							<EventSelector selectedOption={selectedOption} onChange={handleChange} />
							<Input type="hidden" {...register('icon')} />
						</div>

						<div className="EventAdd_Content_Date">
							<Controller
								control={control}
								name="date"
								render={({ field }) => {
									const { value, onChange, ref, ...rest } = field
									return (
										<DateSelector
											customInput={
												<CustomDateSelector
													icon="calendar_month"
													className="EventAdd_Date"
													text={formatDisplayDate(languageStore.selectedLanguage?.isoCode + '', value, DateTime.DATE_MED)}
												/>
											}
											dateFormat="Pp"
											innerRef={ref}
											autoComplete="off"
											maxDate={new Date()}
											invalid={Boolean(errors?.date?.message)}
											invalidMessage={errors?.date?.message}
											selected={value}
											onChange={date => onChange(date)}
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											{...rest}
										/>
									)
								}}
							/>
						</div>
					</div>

					{isCustomSelected && (
						<Input
							placeholder={t('form:eventTitle.placeholder')}
							invalid={Boolean(errors.title?.message)}
							invalidMessage={errors.title?.message}
							{...register('title')}
						/>
					)}

					<FormGroup>
						<TextArea
							resize="vertical"
							placeholder={t('form:event.placeholder')}
							invalid={Boolean(errors.content?.message)}
							invalidMessage={errors.content?.message}
							{...register('content')}
						/>
					</FormGroup>
				</Form>
			</div>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Private(EventAdd)
