import './TimelineLoading.scss'

// Store
import useJournalStore from '../../../store/journalStore'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

const TimelineLoading = () => {
	const journalStore = useJournalStore()

	if (journalStore.loading && !journalStore.error) {
		return <div className="TimelineLoading"><Loading /></div>
	}

	return null
}

export default TimelineLoading
