import type React from 'react'
import './EventSelector.scss'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Config
import eventOptions from '../../../ruya-shared/shared/config/eventOptions'

// Localization
import { useTranslation } from 'react-i18next'

interface Option {
	icon: string
	name: string
}

interface EventSelectorProps {
	selectedOption: Option
	onChange: (selectedName: string) => void
}

const EventSelector: React.FC<EventSelectorProps> = ({ selectedOption, onChange }) => {
	// Translation Hooks
	const { t } = useTranslation()

	// Check if selectedOption has the custom icon
	const isCustom = selectedOption.icon === eventOptions[eventOptions.length - 1].icon

	// If it's custom
	const selectedValue = isCustom ? eventOptions[eventOptions.length - 1].name : selectedOption.name

	return (
		<div className="EventSelector">
			<MaterialSymbol name={selectedOption.icon}/>

			<select className="EventSelector_Select" value={selectedValue} onChange={e => onChange(e.target.value)}>
				{eventOptions.map(option => (
					<option key={option.name} value={option.name}>
						{t('eventTypes:' + option.name)}
					</option>
				))}
			</select>
		</div>
	)
}

export default EventSelector
