import { useEffect } from 'react'

// Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Utils
import ScrollToTop from '../ruya-shared/shared/utils/ScrollTop'

// Private Route
import Private from './Private'

// Public pages
import Login from '../ui/pages/login/Login'
import PublicLanguages from '../ui/pages/publicLanguages/PublicLanguages'
import AccountStatus from '../ui/pages/accountStatus/AccountStatus'
import SignUp from '../ui/pages/signUp/SignUp'
import ForgotPassword from '../ui/pages/forgotPassword/ForgotPassword'
import ResetPassword from '../ui/pages/resetPassword/ResetPassword'
import Activation from '../ui/pages/activation/Activation'
import NotFound from '../ui/pages/notFound/NotFound'

// App Challenge
import SecurityChallenge from '../ui/pages/securityChallenge/SecurityChallenge'

// Private pages
import Journal from '../ui/pages/journal/Journal'
import DiaryAdd from '../ui/pages/diaryAdd/DiaryAdd'
import DiaryView from '../ui/pages/diaryView/DiaryView'
import DiaryEdit from '../ui/pages/diaryEdit/DiaryEdit'
import EventAdd from '../ui/pages/eventAdd/EventAdd'
import EventEdit from '../ui/pages/eventEdit/EventEdit'
import DreamAdd from '../ui/pages/dreamAdd/DreamAdd'
import DreamView from '../ui/pages/dreamView/DreamView'
import DreamEdit from '../ui/pages/dreamEdit/DreamEdit'
import InterpreterSelection from '../ui/pages/interpreterSelection/InterpreterSelection'
import DreamInterpretation from '../ui/pages/dreamInterpretation/DreamInterpretation'
import DreamViewInterpretation from '../ui/pages/dreamViewInterpretation/DreamViewInterpretation'

import Profile from '../ui/pages/profile/Profile'
import Languages from '../ui/pages/languages/Languages'
import ChangePassword from '../ui/pages/changePassword/ChangePassword'
import Logout from '../ui/pages/logout/Logout'
import Subscriptions from '../ui/pages/subscriptions/Subscriptions'

// Store
import useMainStore from '../store/mainStore'

// Config
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Router config
const AppRouter = () => {
	// Load navigation data
	const mainStore = useMainStore()

	useEffect(() => {
		// If path is commonSettings.apps.web.paths.challenge+ '/:lang' don't trigger onAppLoad
		if (window.location.pathname.includes(commonSettings.apps.web.paths.challenge)) {
			return
		}

		mainStore.onAppLoad()
	}, [])

	return (
		<Router>
			<ScrollToTop />
			<Routes>
				{/* Public routes */}
				<Route path={commonSettings.apps.web.paths.home} element={<Login />} />
				<Route path={commonSettings.apps.web.paths.login} element={<Login />} />
				<Route path={commonSettings.apps.web.paths.signUp} element={<SignUp />} />
				<Route path={commonSettings.apps.web.paths.language} element={<PublicLanguages />} />
				<Route path={commonSettings.apps.web.paths.accountStatus} element={<AccountStatus />} />
				<Route path={commonSettings.apps.web.paths.forgotPassword} element={<ForgotPassword />} />
				<Route path={commonSettings.apps.web.paths.resetPassword + '/:token'} element={<ResetPassword />} />
				<Route path={commonSettings.apps.web.paths.activation + '/:token'} element={<Activation />} />
				<Route path={commonSettings.apps.web.paths.logout} element={<Logout />} />
				<Route path={commonSettings.apps.web.paths.challenge + '/:lang'} element={<SecurityChallenge />} />

				{/* Private routes */}
				<Route path={commonSettings.apps.web.paths.journal} element={<Private Component={Journal} />} />

				{/* Settings */}
				<Route path={commonSettings.apps.web.paths.profile} element={<Private Component={Profile} />} />
				<Route path={commonSettings.apps.web.paths.loggedInLanguage} element={<Private Component={Languages} />} />
				<Route path={commonSettings.apps.web.paths.changePassword} element={<Private Component={ChangePassword} />} />
				<Route path={commonSettings.apps.web.paths.subscription} element={<Private Component={Subscriptions} />} />

				<Route path={`${commonSettings.apps.web.paths.journal}/dream/add`} element={<Private Component={DreamAdd} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/dream/:timelineId`} element={<Private Component={DreamView} />} />
				<Route
					path={`${commonSettings.apps.web.paths.journal}/dream/edit/:timelineId`}
					element={<Private Component={DreamEdit} />}
				/>
				<Route
					path={`${commonSettings.apps.web.paths.journal}/dream/interpretation/:timelineId/:interpreterId`}
					element={<Private Component={DreamViewInterpretation} />}
				/>
				<Route path={`${commonSettings.apps.web.paths.journal}/diary/add`} element={<Private Component={DiaryAdd} />} />
				<Route path={`${commonSettings.apps.web.paths.journal}/diary/:timelineId`} element={<Private Component={DiaryView} />} />
				<Route
					path={`${commonSettings.apps.web.paths.journal}/diary/edit/:timelineId`}
					element={<Private Component={DiaryEdit} />}
				/>

				<Route path={`${commonSettings.apps.web.paths.journal}/event/add`} element={<Private Component={EventAdd} />} />
				{/* <Route path={`${commonSettings.apps.web.paths.journal}/event/:timelineId`} element={<Private Component={} />} /> */}
				<Route
					path={`${commonSettings.apps.web.paths.journal}/event/edit/:timelineId`}
					element={<Private Component={EventEdit} />}
				/>

				<Route
					path={`${commonSettings.apps.web.paths.journal}/select-interpreter/:timelineId`}
					element={<Private Component={InterpreterSelection} />}
				/>
				<Route
					path={`${commonSettings.apps.web.paths.journal}/dream-questionnaire/:timelineId/:interpreterId`}
					element={<Private Component={DreamInterpretation} />}
				/>
				{/* Not Found */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	)
}

export default AppRouter
